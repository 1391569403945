// CustomRangeSize.js
import { Box, Slider } from "@mui/material";
import React from "react";
import styles from "./CustomRangeSize.module.scss";

const CustomRangeSize = ({ size = [1, 1.5, 2, 3] }) => {
  const marks = size.map((obj, index) => ({ value: index, label: obj }));

  return (
    <Box sx={{ width: "100%" }}>
      <Slider
        sx={{
          color: "white",
          "& .MuiSlider-markLabel": {
            color: "white",
            fontSize: 14,
            fontFamily: "Montserrat",
            "@media(max-width: 767px)": {
              fontSize: 7,
            },
          },
        }}
        max={marks.length - 1}
        aria-label="Custom marks"
        defaultValue={0}
        step={null}
        size={window.innerWidth < 768 ? "small" : "medium"}
        color="primary"
        marks={marks}
        className={styles.range}
      />
    </Box>
  );
};

export default CustomRangeSize;
