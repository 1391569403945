import { useEffect, useState } from "react";
import styles from "./PopupDetailedImage.module.scss";

export default function PopupDetailedImage({
  onClose,
  isOpen,
  card,
  detailedImagesContainer,
  url,
}) {
  const lengthArrayImages = detailedImagesContainer.length;
  const [countImage, setCountImage] = useState(
    findSimilarIndex(detailedImagesContainer, card.image)
  );
  const [isActiveArrayContainer, setIsActiveArrayContainer] = useState(false);

  useEffect(() => {
    // Обновляем countImage, когда меняется card.image
    setCountImage(findSimilarIndex(detailedImagesContainer, card.image));
  }, [card.image, detailedImagesContainer]);

  useEffect(() => {
    if (!isOpen) return;
    const handleOverlayEscClose = (event) => {
      if (event.key === "Escape" && isOpen) {
        setIsActiveArrayContainer(false);
        onClose();
      }
    };
    document.addEventListener("keydown", handleOverlayEscClose);
    return () => {
      document.removeEventListener("keydown", handleOverlayEscClose);
    };
  }, [onClose, isOpen]);

  function handleClickBtnRight() {
    setIsActiveArrayContainer(true);
    if (countImage < lengthArrayImages - 1) {
      setCountImage(countImage + 1);
    } else {
      setCountImage(0);
    }
  }

  function handleClickBtnLeft() {
    setIsActiveArrayContainer(true);
    if (countImage > 0) {
      setCountImage(countImage - 1);
    } else {
      setCountImage(lengthArrayImages - 1);
    }
  }

  function findSimilarIndex(array, image) {
    for (let i = 0; i < array.length; i++) {
      if (`${url}/${array[i]}` === image) {
        return i;
      }
    }
    return -1; // Возвращаем -1, если не найдено совпадений
  }
  return (
    <div
      className={`${styles.popupDetailedImage} ${
        isOpen ? styles.popupDetailedImage_opened : ""
      }`}
      onClick={() => {
        setIsActiveArrayContainer(false);
        onClose();
      }}
    >
      <figure
        className={styles.popupDetailedImage__figure}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          className={styles.popupDetailedImage__image}
          src={
            isActiveArrayContainer
              ? `${url}/${detailedImagesContainer[countImage]}`
              : card.image
          }
          alt={card.name}
        />
        {detailedImagesContainer.length > 1 && (
          <>
            <div
              className={styles.popupDetailedImage__btnLeft}
              onClick={handleClickBtnLeft}
            ></div>
            <div
              className={styles.popupDetailedImage__btnRight}
              onClick={handleClickBtnRight}
            ></div>
          </>
        )}
      </figure>
    </div>
  );
}
