import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { getBrands } from "../../app/features/brand.slice";
import { getFilterData } from "../../app/features/filter.slice";
import { getProducts } from "../../app/features/product.slice";
import { motion } from "framer-motion";
import styles from "./PopupFilter.module.scss";

export default function PopupFilter({ isActive, setActive }) {
  const dispatch = useDispatch();
  const [openBrandList, setOpenBrandList] = useState(false);
  const [openProductTypeList, setOpenProductTypeList] = useState(false);
  const [openGenderList, setOpenGenderList] = useState(false);
  const [openPriceList, setOpenPriceList] = useState(false);

  const brands = useSelector((state) => state.brand.data);
  const products = useSelector((state) => state.product.data);

  const [brandsFilterList, setBrandsFilterList] = useState([]);
  const [productsFilterList, setProductsFilterList] = useState([]);
  const [gender, setGender] = useState("");
  const [price, setPrice] = useState({
    $lte: "",
    $gte: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const formatPrice = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  useEffect(() => {
    !brands.length && dispatch(getBrands());
    !products.length && dispatch(getProducts());
  }, []);

  const handleBrandsList = (brandId) => {
    setBrandsFilterList((prevState) => {
      if (prevState.includes(brandId)) {
        return prevState.filter((brand) => brand !== brandId);
      }
      return [...prevState, brandId];
    });
  };

  const handleProductsFilterList = (selectProduct) => {
    setProductsFilterList((prevState) => {
      if (prevState.includes(selectProduct)) {
        return prevState.filter((product) => product !== selectProduct);
      }
      return [...prevState, selectProduct];
    });
  };

  const handleSelectGender = (selectedGender) => {
    setGender(selectedGender);
  };

  const handleClickExitPopup = (e) => {
    if (e.target === e.currentTarget) {
      setActive(false);
    }
  };

  const handleFilterButton = () => {
    if (location.pathname !== "/filter") {
      navigate("/filter");
    }
    setActive(false);

    const filterKeys = {};
    brandsFilterList.length && (filterKeys.brand = brandsFilterList);
    productsFilterList.length && (filterKeys.product = productsFilterList);
    gender && (filterKeys.gender = gender);
    Object.keys(price).length &&
      (price.$gte || price.$lte) &&
      (filterKeys.price = price);

    Object.keys(filterKeys).length && dispatch(getFilterData(filterKeys));
  };

  const checkFilter = Boolean(
    brandsFilterList.length ||
      productsFilterList.length ||
      gender ||
      price.$gte ||
      price.$lte
  );

  const handleRefreshFilter = () => {
    setBrandsFilterList([]);
    setProductsFilterList([]);
    setGender("");
    setPrice({
      $gte: "",
      $lte: "",
    });
  };

  return (
    <>
      <motion.div
        animate={{ x: isActive ? 0 : "200vw", transition: { duration: 0.1 } }}
        className={`${styles.popupFilter} ${
          isActive ? styles.popupFilter_active : ""
        } `}
        onClick={handleClickExitPopup}
      >
        <div className={styles.popupFilter__container}>
          <div
            className={styles.popupFilter__filterIcon}
            onClick={handleClickExitPopup}
          />
          <h4 className={styles.popupFilter__title}>ФИЛЬТР</h4>
          <ul className={styles.popupFilter__list}>
            <li className={styles.popupFilter__item}>
              <p
                className={styles.popupFilter__textItem}
                onClick={() => setOpenBrandList(!openBrandList)}
              >
                {openBrandList ? "▲" : "▼"} БРЕНД
              </p>
              {openBrandList && (
                <ul className={styles.popupFilter__openList}>
                  {brands.map((brand) => {
                    return (
                      <li
                        className={styles.popupFilter__openItem}
                        key={brand._id}
                        onClick={() => handleBrandsList(brand._id)}
                      >
                        <p
                          className={`${styles.popupFilter__openText} ${
                            brandsFilterList.includes(brand._id)
                              ? styles.popupFilter__openText_active
                              : ""
                          }`}
                        >
                          {brand?.nameOfTheBrand}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
            <li className={styles.popupFilter__item}>
              <p
                className={styles.popupFilter__textItem}
                onClick={() => setOpenProductTypeList(!openProductTypeList)}
              >
                {openProductTypeList ? "▲" : "▼"} ТИП ИЗДЕЛИЯ
              </p>
              {openProductTypeList && (
                <ul className={styles.popupFilter__openList}>
                  {products.map((product) => {
                    return (
                      <li
                        className={styles.popupFilter__openItem}
                        key={product._id}
                        onClick={() =>
                          handleProductsFilterList(product.nameOfTheProduct)
                        }
                      >
                        <p
                          className={`${styles.popupFilter__openText} ${
                            productsFilterList.includes(
                              product.nameOfTheProduct
                            )
                              ? styles.popupFilter__openText_active
                              : ""
                          }`}
                        >
                          {product.nameOfTheProduct}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
            <li className={styles.popupFilter__item}>
              <p
                className={styles.popupFilter__textItem}
                onClick={() => setOpenGenderList(!openGenderList)}
              >
                {openGenderList ? "▲" : "▼"} ПОЛ
              </p>
              {openGenderList && (
                <ul className={styles.popupFilter__openListGender}>
                  <li className={styles.customRadio}>
                    <label>
                      <input
                        type="radio"
                        value="мужской"
                        checked={gender === "мужской"}
                        onChange={(e) => handleSelectGender(e.target.value)}
                        onClick={(e) =>
                          gender === e.target.value && setGender("")
                        }
                      />
                      <div className={styles.customRadio__label}>
                        <p className={styles.popupFilter__openText}>Мужской</p>
                      </div>
                    </label>
                  </li>
                  <li className={styles.clear}></li>
                  <li className={styles.customRadio}>
                    <label>
                      <input
                        type="radio"
                        value="женский"
                        checked={gender === "женский"}
                        onChange={(e) => handleSelectGender(e.target.value)}
                        onClick={(e) =>
                          gender === e.target.value && setGender("")
                        }
                      />
                      <div className={styles.customRadio__label}>
                        <p className={styles.popupFilter__openText}>Женский</p>
                      </div>
                    </label>
                  </li>
                </ul>
              )}
            </li>
            <li className={styles.popupFilter__item}>
              <p
                className={styles.popupFilter__textItem}
                onClick={() => setOpenPriceList(!openPriceList)}
              >
                {openPriceList ? "▲" : "▼"} ЦЕНА
              </p>
              {openPriceList && (
                <ul className={styles.popupFilter__openListPrice}>
                  <li className={styles.popupFilter__openItemPrice}>
                    <p className={styles.popupFilter__openText}>от</p>
                    <input
                      className={styles.popupFilter__priceInput}
                      type="text"
                      placeholder="1 000 000 ₽"
                      value={formatPrice(price.$gte)}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/\D/g, ""); // Удаляем все, кроме цифр
                        /*if (newValue) setPrice({ ...price, $gte: newValue });*/
                        setPrice({ ...price, $gte: newValue });
                      }}
                      onKeyPress={(e) => {
                        if (e.key < "0" || e.key > "9") {
                          e.preventDefault(); // Блокируем ввод нечисловых символов
                        }
                      }}
                    />
                  </li>
                  <li className={styles.popupFilter__openItemPrice}>
                    <p className={styles.popupFilter__openText}>до</p>
                    <input
                      className={styles.popupFilter__priceInput}
                      type="text"
                      value={formatPrice(price.$lte)}
                      placeholder="1 000 000 ₽"
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/\D/g, ""); // Удаляем все, кроме цифр
                        /*if (newValue) setPrice({ ...price, $lte: newValue });*/
                        setPrice({ ...price, $lte: newValue });
                      }}
                      onKeyPress={(e) => {
                        if (e.key < "0" || e.key > "9") {
                          e.preventDefault(); // Блокируем ввод нечисловых символов
                        }
                      }}
                    />
                  </li>
                </ul>
              )}
            </li>
          </ul>
          <div className={styles.buttons}>
            <Button
              disabled={!checkFilter}
              color="success"
              className={styles["admin__button-add"]}
              variant="outlined"
              onClick={handleFilterButton}
            >
              Применить
            </Button>
            <Button
              disabled={!checkFilter}
              color="success"
              className={styles["admin__button-add"]}
              variant="outlined"
              onClick={handleRefreshFilter}
            >
              Сбросить фильтр
            </Button>
          </div>
        </div>
      </motion.div>

      <div
        className={`${styles.popupFilterMob} ${
          isActive ? styles.popupFilterMob_active : ""
        } `}
        onClick={handleClickExitPopup}
      >
        <div className={styles.popupFilterMob__container}>
          <div
            className={styles.popupFilterMob__filterIcon}
            onClick={handleClickExitPopup}
          />
          <h4 className={styles.popupFilterMob__title}>ФИЛЬТР</h4>
          <ul className={styles.popupFilterMob__list}>
            <li className={styles.popupFilterMob__item}>
              <p
                className={styles.popupFilterMob__textItem}
                onClick={() => setOpenPriceList(!openPriceList)}
              >
                ЦЕНА
              </p>
              <ul className={styles.popupFilterMob__openListPrice}>
                <li className={styles.popupFilterMob__openItemPrice}>
                  <p className={styles.popupFilterMob__openText}>от</p>
                  <input
                    className={styles.popupFilterMob__priceInput}
                    type="text"
                    value={formatPrice(price.$gte)}
                    placeholder="1 000 000 ₽"
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\D/g, ""); // Удаляем все, кроме цифр
                      setPrice({ ...price, $gte: newValue });
                    }}
                    onKeyPress={(e) => {
                      if (e.key < "0" || e.key > "9") {
                        e.preventDefault(); // Блокируем ввод нечисловых символов
                      }
                    }}
                  />
                </li>
                <li className={styles.popupFilterMob__openItemPrice}>
                  <p className={styles.popupFilterMob__openText}>до</p>
                  <input
                    className={styles.popupFilterMob__priceInput}
                    type="text"
                    value={formatPrice(price.$lte)}
                    placeholder="1 000 000 ₽"
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\D/g, ""); // Удаляем все, кроме цифр
                      setPrice({ ...price, $lte: newValue });
                    }}
                    onKeyPress={(e) => {
                      if (e.key < "0" || e.key > "9") {
                        e.preventDefault(); // Блокируем ввод нечисловых символов
                      }
                    }}
                  />
                </li>
              </ul>
            </li>
            <li className={styles.popupFilterMob__item}>
              <p
                className={styles.popupFilterMob__textItem}
                onClick={() => setOpenBrandList(!openBrandList)}
              >
                БРЕНД
              </p>
              <ul className={styles.popupFilterMob__openList}>
                {brands.map((brand) => {
                  return (
                    <li
                      className={styles.popupFilterMob__openItem}
                      key={brand._id}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <label className={styles.popupFilterMob__inputCheckLabel}>
                        <p className={styles.popupFilterMob__openText}>
                          {brand?.nameOfTheBrand}
                        </p>
                        <input
                          className={styles.popupFilterMob__inputCheck}
                          type="checkbox"
                          name={`${brand?.nameOfTheBrand}Check`}
                          checked={brandsFilterList.includes(brand._id)}
                          onChange={() => handleBrandsList(brand._id)}
                        />
                        <div
                          className={styles.popupFilterMob__inputCheckBlock}
                        ></div>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </li>

            <li className={styles.popupFilterMob__item}>
              <p
                className={styles.popupFilterMob__textItem}
                onClick={() => setOpenProductTypeList(!openProductTypeList)}
              >
                ТИП ИЗДЕЛИЯ
              </p>
              <ul className={styles.popupFilterMob__openList}>
                {products.map((type) => {
                  return (
                    <li
                      className={styles.popupFilterMob__openItem}
                      key={type._id}
                    >
                      <label className={styles.popupFilterMob__inputCheckLabel}>
                        <p className={styles.popupFilterMob__openText}>
                          {type.nameOfTheProduct}
                        </p>
                        <input
                          className={styles.popupFilterMob__inputCheck}
                          type="checkbox"
                          name={`${type}Check`}
                          checked={productsFilterList.includes(
                            type.nameOfTheProduct.toLowerCase()
                          )}
                          onChange={() =>
                            handleProductsFilterList(
                              type.nameOfTheProduct.toLowerCase()
                            )
                          }
                        />
                        <div
                          className={styles.popupFilterMob__inputCheckBlock}
                        ></div>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li className={styles.popupFilterMob__item}>
              <p
                className={styles.popupFilterMob__textItem}
                onClick={() => setOpenGenderList(!openGenderList)}
              >
                ПОЛ
              </p>
              <ul className={styles.popupFilterMob__openListGender}>
                <li className={styles.customRadio}>
                  <label>
                    <input
                      type="radio"
                      value="мужской"
                      checked={gender === "мужской"}
                      onChange={(e) => handleSelectGender(e.target.value)}
                      onClick={(e) =>
                        gender === e.target.value && setGender("")
                      }
                    />
                    <div className={styles.customRadio__label}>
                      <p className={styles.popupFilterMob__openText}>Мужской</p>
                    </div>
                  </label>
                </li>
                <li className={styles.clear}></li>
                <li className={styles.customRadio}>
                  <label>
                    <input
                      type="radio"
                      value="женский"
                      checked={gender === "женский"}
                      onChange={(e) => handleSelectGender(e.target.value)}
                      onClick={(e) =>
                        gender === e.target.value && setGender("")
                      }
                    />
                    <div className={styles.customRadio__label}>
                      <p className={styles.popupFilterMob__openText}>Женский</p>
                    </div>
                  </label>
                </li>
              </ul>
            </li>
          </ul>
          <div className={styles.buttons}>
            <Button
              disabled={!checkFilter}
              color="success"
              className={styles["admin__button-add"]}
              variant="outlined"
              onClick={handleFilterButton}
            >
              Применить
            </Button>
            <Button
              disabled={!checkFilter}
              color="success"
              className={styles["admin__button-add"]}
              variant="outlined"
              onClick={handleRefreshFilter}
            >
              Сбросить фильтр
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
