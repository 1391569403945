import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { exit } from "../../app/features/application.slice";
import styles from "./adminpanel.module.scss";

const AdminPanel = ({ isActive, setActive }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickExitPopup = (e) => {
    if (e.target === e.currentTarget) {
      setActive(false);
    }
  };
  const addBrandNavigate = () => {
    navigate("/admin/brands");
    setActive(false);
  };

  const addWatchNavigate = () => {
    navigate("/watches/admin/create");
    setActive(false);
  };

  const addAccessoriesNavigate = () => {
    navigate("/accessories/admin/create");
    setActive(false);
  };

  const addJewerlyNavigate = () => {
    navigate("/jewelries/admin/create");
    setActive(false);
  };

  const exitAdminProfile = () => {
    localStorage.removeItem("admin");
    localStorage.removeItem("token");
    setActive(false);
    navigate("/");
    dispatch(exit());
  };
  return (
    <div
      className={`${styles["admin-panel"]} ${
        isActive ? styles["admin-panel__active"] : ""
      }`}
      onClick={(e) => handleClickExitPopup(e)}
    >
      <div className={styles.container}>
        <ul className={styles.list}>
          <li className={styles.items} onClick={() => addBrandNavigate()}>
            Бренды
          </li>
          <li className={styles.items} onClick={() => addWatchNavigate()}>
            Добавить часы
          </li>
          <li className={styles.items} onClick={() => addJewerlyNavigate()}>
            Добавить ювелирное изделие
          </li>
          <li className={styles.items} onClick={() => addAccessoriesNavigate()}>
            Добавить аксессуар
          </li>
          <li className={styles.items} onClick={() => exitAdminProfile()}>
            Выйти
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminPanel;
