import React, { useEffect, useState } from "react";
import styles from "./watchloader.module.scss";
import { useLocation } from "react-router-dom";

const WatchLoader = () => {
  const [visible, setVisible] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") setVisible(false);
    else {
      setVisible(true);
    }
  }, []);
  return (
    <>
      {visible && (
        <div className={styles.wrapper}>
          <div className={styles["clock-loader"]}></div>
        </div>
      )}
    </>
  );
};

export default WatchLoader;
