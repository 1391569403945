import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  data: [],
  loading: true,
  error: null,
  msg: {},
};

export const getNews = createAsyncThunk("get/news", async (_, thunkAPI) => {
  try {
    const res = await axios.get(`${config.proxy}/news`);
    const data = res.data;

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteNews = createAsyncThunk(
  "delete/news",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${config.proxy}/news/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createNews = createAsyncThunk(
  "add/news",
  async ({ title, text, news }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const postData = new FormData();

      postData.append("title", title);
      postData.append("text", text);

      for (let i = 0; i < news.length; i++) {
        postData.append("news", news[i]);
      }

      const res = await axios.post(`${config.proxy}/news`, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = res.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateNews = createAsyncThunk(
  "update/news",
  async ({ id, title, text, news, oldImages, images }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const postData = new FormData();

      postData.append("title", title);
      postData.append("text", text);

      if (images.length > 0 && images) {
        images.forEach((image, index) => {
          postData.append(`images[${index}]`, image);
        });
      }

      if (oldImages.length > 0 && oldImages) {
        oldImages.forEach((image) => {
          postData.append("oldImagesPath", image);
        });
      }

      for (let i = 0; i < news.length; i++) {
        postData.append("news", news[i]);
      }

      const response = await axios.patch(
        `${config.proxy}/news/${id}`,
        postData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNews.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getNews.fulfilled, (state, actions) => {
        state.loading = false;
        state.error = null;
        state.data = actions.payload;
      })

      .addCase(createNews.fulfilled, (state, actions) => {
        state.data.unshift(actions.payload);
      })

      .addCase(updateNews.fulfilled, (state, actions) => {
        state.data = state.data.map((item) => {
          if (item._id === actions.payload._id) {
            return actions.payload;
          }
          return item;
        });
      })

      .addCase(deleteNews.fulfilled, (state, actions) => {
        state.data = state.data.filter((item) => item._id !== actions.payload);
      });
  },
});

export default newsSlice.reducer;
