import styles from "./notFound.module.scss";
export default function NotFound() {
  return (
    <main className={styles.notFound}>
      <h2 className={styles.notFound__title}>
        Увы... Такой страницы не существует!
      </h2>
    </main>
  );
}
