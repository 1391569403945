import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  data: [],
  recommendation: [],
  currentJewelry: {},
  createdJewelry: {},
  editJewelry: {},
  error: null,
  loading: null,
  message: "",
  filterRequest: false,
};

export const getJewelry = createAsyncThunk(
  "get/jewelry",
  async (filterKeys = {}, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        config.proxy + "/jewelry/all",
        filterKeys,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editAvailability = createAsyncThunk(
  "edit/jewelry/availability",
  async ({ id, availability }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${config.proxy}/jewelry/update-availability/${id}`,
        { availability },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.data;
      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRecommendation = createAsyncThunk(
  "get/jewelry/recommendation",
  async (filterKeys = {}, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        config.proxy + "/jewelry/recommendation",
        filterKeys,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getJewelryById = createAsyncThunk(
  "get/jewelry/id",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(config.proxy + `/jewelry/${id}`);
      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addJewelry = createAsyncThunk(
  "add/jewelry",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const postData = new FormData();

      postData.append("brand", formData.brand);
      postData.append("nameOfTheJewelry", formData.nameOfTheJewelry);
      postData.append("mainJewelry", formData.mainJewelry);
      postData.append("availability", formData.availability);
      postData.append("information", JSON.stringify(formData.information));
      postData.append("kit", formData.kit);
      postData.append("product", formData.product);
      postData.append("price", formData.price);
      postData.append("gender", formData.gender);
      postData.append("size", formData.size);
      postData.append("currency", formData.currency);

      formData.jewelry.forEach((jewelryFile) => {
        postData.append(`jewelry`, jewelryFile);
      });

      const response = await axios.post(`${config.proxy}/jewelry`, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const editJewelryById = createAsyncThunk(
  "edit/jewelry/id",
  async ({ formData, id }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const postData = new FormData();

      if (formData.brand?._id) {
        postData.append("brand", formData.brand?._id);
      } else if (formData.brand) {
        postData.append("brand", formData.brand);
      }

      if (formData.nameOfTheJewelry) {
        postData.append("nameOfTheJewelry", formData.nameOfTheJewelry);
      }

      if (formData.product) {
        postData.append("product", formData.product);
      }

      if (formData.kit) {
        postData.append("kit", formData.kit);
      }

      postData.append("availability", formData.availability);

      if (formData.images && formData.images.length > 0) {
        formData.images.forEach((image) => {
          postData.append(`jewelryImagesPath`, image);
        });
      }

      if (formData.oldImages && formData.oldImages.length > 0) {
        formData.oldImages.forEach((image) => {
          postData.append(`oldImages`, image);
        });
      }

      if (formData.mainImage) {
        postData.append("mainImagePath", formData.mainImage);
      }

      if (formData.mainJewelry) {
        postData.append("mainJewelry", formData.mainJewelry);
      }

      if (formData.information) {
        postData.append("information", JSON.stringify(formData.information));
      }

      postData.append("price", formData.price);
      postData.append("gender", formData.gender);
      postData.append("size", formData.size);

      if (formData.currency) postData.append("currency", formData.currency);

      if (formData.jewelry && formData.jewelry.length > 0) {
        formData.jewelry.forEach((jewelryFile) => {
          postData.append("jewelry", jewelryFile);
        });
      }

      const response = await axios.patch(
        `${config.proxy}/jewelry/${id}`,
        postData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteJewelryById = createAsyncThunk(
  "delete/jewelry/id",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.delete(`${config.proxy}/jewelry/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const jewelrySlice = createSlice({
  name: "jewelry",
  initialState,
  reducers: {
    clearCreatedJewelry: (state) => {
      state.createdJewelry = {};
    },
    clearEditJewelry: (state) => {
      state.editJewelry = {};
    },
    clearCurrentJewelry: (state) => {
      state.currentJewelry = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // GET WATCH
      .addCase(getJewelry.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
        state.filterRequest = true;
      })
      .addCase(getJewelry.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getJewelry.fulfilled, (state, actions) => {
        state.data = actions.payload;
        state.loading = false;
        state.error = null;
        state.filterRequest = false;
      })

      .addCase(getRecommendation.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRecommendation.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getRecommendation.fulfilled, (state, actions) => {
        state.recommendation = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(addJewelry.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addJewelry.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(addJewelry.fulfilled, (state, actions) => {
        state.createdJewelry = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(getJewelryById.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJewelryById.rejected, (state, actions) => {
        state.error = actions.payload.message;
        state.loading = false;
      })
      .addCase(getJewelryById.fulfilled, (state, actions) => {
        state.currentJewelry = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(deleteJewelryById.pending, (state, actions) => {
        state.error = null;
      })
      .addCase(deleteJewelryById.rejected, (state, actions) => {
        state.error = actions.payload;
      })
      .addCase(deleteJewelryById.fulfilled, (state, actions) => {
        state.message = actions.payload.message;
        state.data = state.data.filter(
          (card) => card._id !== actions.payload.id
        );
        state.error = null;
      })

      .addCase(editJewelryById.pending, (state, actions) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(editJewelryById.rejected, (state, actions) => {
        state.error = actions.payload;
        state.loading = false;
      })
      .addCase(editJewelryById.fulfilled, (state, actions) => {
        state.editJewelry = actions.payload;
        state.loading = false;
        state.error = null;
      });
  },
});

export const { clearCreatedJewelry, clearEditJewelry, clearCurrentJewelry } =
  jewelrySlice.actions;

export default jewelrySlice.reducer;
