import { useEffect, useRef, useState } from "react";
import { config } from "../../config";
import styles from "./WatchSlider.module.scss";

export default function WatchSlider({ data, handleCardClick }) {
  const [sliderCount, setSliderCount] = useState(0);

  const sliderContainerRef = useRef(null);
  const [sliderContainerWidth, setSliderContainerWidth] = useState(0);

  let sliderItemWidth = `${sliderContainerWidth / 3}px`;

  useEffect(() => {
    if (sliderContainerRef.current) {
      setSliderContainerWidth(sliderContainerRef.current.offsetWidth);
    }
  }, [data]);

  function prevSlider() {
    setSliderCount((prevCount) =>
      prevCount > 0 ? prevCount - 1 : data.length - 1
    );
  }

  function nextSlider() {
    setSliderCount((prevCount) =>
      prevCount < data.length - 1 ? prevCount + 1 : 0
    );
  }

  return (
    <div className={`${data ? styles.sliderWatch : styles.sliderWatch_none}`}>
      <div className={styles.sliderWatch__container} ref={sliderContainerRef}>
        <div className={styles.sliderWatch__list}>
          <div
            className={styles.sliderWatch__containerSlider}
            style={{
              transform: `translateX(${
                -sliderCount * (sliderContainerWidth / 3 + 100)
              }px)`,
            }}
          >
            {data?.map((item, index) => (
              <img
                key={index}
                src={`${config.proxy}/${item}`}
                alt="watch"
                className={styles.sliderWatch__image}
                style={{ width: sliderItemWidth }}
                onClick={() => handleCardClick(item)}
              />
            ))}
          </div>
        </div>
        {data.length > 3 && (
          <>
            <div
              className={styles.sliderWatch__btnLeft}
              onClick={prevSlider}
            ></div>
            <div
              className={styles.sliderWatch__btnRight}
              onClick={nextSlider}
            ></div>
          </>
        )}
      </div>
    </div>
  );
}
