import React from "react";
import styles from "./headersubnav.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import GoBackButton from "../GoBackButton/GoBackButton";

const HeaderSubNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const handleNavigateMainPage = () => {
    navigate("/");
  };
  const handleNavigateFilterPage = () => {
    navigate("/filter");
  };

  return (
    <div className={styles.headerSub}>
      <nav className={styles.headerSub__subNav}>
        <ul className={styles.headerSub__navList}>
          {pathname !== "/" && pathname !== "/filter" && (
            <GoBackButton path={"/filter"} />
          )}
          <li
            className={styles.headerSub__navItem}
            onClick={handleNavigateMainPage}
          >
            <p
              id={"headerNavText"}
              className={`${styles.headerSub__navText} ${
                pathname === "/" ? styles.headerSub__navText_active : ""
              }`}
            >
              новинки
            </p>
          </li>
          <div className={styles.headerSub__lineHeight} />
          <li
            className={styles.headerSub__navItem}
            onClick={handleNavigateFilterPage}
          >
            <p
              id={"headerNavText"}
              className={`${styles.headerSub__navText} ${
                pathname === "/filter" ? styles.headerSub__navText_active : ""
              }`}
            >
              ВСЕ МОДЕЛИ
            </p>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HeaderSubNav;
