import Button from "@mui/material/Button";
import React from "react";
import sortIcon from "../../images/watch__sortIcon.svg";
import sortIconActiveLength from "../../images/watch__sortIconActiveSize.svg";
import styles from "./filterinfo.module.scss";

const FilterInfo = ({
  isActiveLengthSort,
  handleClickIsActiveSort,
  openFilter,
  admin,
  text = "Добавить Ювелирное украшение",
  handleClickCreateJewelryPage,
}) => {
  return (
    <div className={styles.filter}>
      <div className={styles.filter__info}>
        {admin && (
          <div style={{ flex: 1 }}>
            <Button
              color="success"
              className={styles["admin__button-add"]}
              variant="outlined"
              onClick={handleClickCreateJewelryPage}
            >
              {text}
            </Button>
          </div>
        )}
        <div
          className={styles.filter__sortIcon}
          style={{
            backgroundImage: `url(${
              isActiveLengthSort ? sortIconActiveLength : sortIcon
            })`,
          }}
          onClick={handleClickIsActiveSort}
        />
        <div className={styles.filter__icon} onClick={openFilter}></div>
      </div>
    </div>
  );
};

export default FilterInfo;
