import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  data: [],
  recommendation: [],
  currentAccessories: {},
  createdAccessories: {},
  editAccessories: {},
  error: null,
  loading: null,
  message: "",
  filterRequest: false,
};

export const getAccessories = createAsyncThunk(
  "get/accessories",
  async (filterKeys = {}, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        config.proxy + "/accessories/all",
        filterKeys,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editAvailability = createAsyncThunk(
  "edit/accessories/availability",
  async ({ id, availability }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${config.proxy}/accessories/update-availability/${id}`,
        { availability },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.data;
      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRecommendation = createAsyncThunk(
  "get/accessories/recommendation",
  async (filterKeys = {}, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        config.proxy + "/accessories/recommendation",
        filterKeys,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAccessoriesById = createAsyncThunk(
  "get/accessories/id",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(config.proxy + `/accessories/${id}`);
      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addAccessories = createAsyncThunk(
  "add/accessories",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const postData = new FormData();

      postData.append("brand", formData.brand);
      postData.append("nameOfTheAccessories", formData.nameOfTheAccessories);
      postData.append("mainAccessories", formData.mainAccessories);
      postData.append("availability", formData.availability);
      postData.append("information", JSON.stringify(formData.information));
      postData.append("kit", formData.kit);
      postData.append("price", formData.price);
      postData.append("gender", formData.gender);
      postData.append("size", formData.size);
      postData.append("currency", formData.currency);

      formData.accessories.forEach((accessoriesFile) => {
        postData.append(`accessories`, accessoriesFile);
      });

      const response = await axios.post(
        `${config.proxy}/accessories`,
        postData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const editAccessoriesById = createAsyncThunk(
  "edit/accessories/id",
  async ({ formData, id }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const postData = new FormData();

      if (formData.brand?._id) {
        postData.append("brand", formData.brand?._id);
      } else if (formData.brand) {
        postData.append("brand", formData.brand);
      }

      if (formData.nameOfTheAccessories) {
        postData.append("nameOfTheAccessories", formData.nameOfTheAccessories);
      }

      if (formData.kit) {
        postData.append("kit", formData.kit);
      }

      postData.append("availability", formData.availability);
      postData.append("currency", formData.currency);

      if (formData.images && formData.images.length > 0) {
        formData.images.forEach((image) => {
          postData.append(`accessoriesImagesPath`, image);
        });
      }

      if (formData.oldImages && formData.oldImages.length > 0) {
        formData.oldImages.forEach((image) => {
          postData.append(`oldImages`, image);
        });
      }

      if (formData.mainImage) {
        postData.append("mainImagePath", formData.mainImage);
      }

      if (formData.mainAccessories) {
        postData.append("mainAccessories", formData.mainAccessories);
      }

      if (formData.information) {
        postData.append("information", JSON.stringify(formData.information));
      }

      postData.append("price", formData.price);
      postData.append("gender", formData.gender);
      postData.append("size", formData.size);

      if (formData.accessories && formData.accessories.length > 0) {
        formData.accessories.forEach((accessoriesFile) => {
          postData.append("accessories", accessoriesFile);
        });
      }

      const response = await axios.patch(
        `${config.proxy}/accessories/${id}`,
        postData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAccessoriesById = createAsyncThunk(
  "delete/accessories/id",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.delete(`${config.proxy}/accessories/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const accessoriesSlice = createSlice({
  name: "accessories",
  initialState,
  reducers: {
    clearCreatedAccessories: (state) => {
      state.createdAccessories = {};
    },
    clearEditAccessories: (state) => {
      state.editAccessories = {};
    },
    clearCurrentAccessories: (state) => {
      state.currentAccessories = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // GET WATCH
      .addCase(getAccessories.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
        state.filterRequest = true;
      })
      .addCase(getAccessories.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAccessories.fulfilled, (state, actions) => {
        state.data = actions.payload;
        state.loading = false;
        state.error = null;
        state.filterRequest = false;
      })

      .addCase(getRecommendation.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRecommendation.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getRecommendation.fulfilled, (state, actions) => {
        state.recommendation = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(addAccessories.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAccessories.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(addAccessories.fulfilled, (state, actions) => {
        state.createdAccessories = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(getAccessoriesById.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAccessoriesById.rejected, (state, actions) => {
        state.error = actions.payload.message;
        state.loading = false;
      })
      .addCase(getAccessoriesById.fulfilled, (state, actions) => {
        state.currentAccessories = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(deleteAccessoriesById.pending, (state, actions) => {
        state.error = null;
      })
      .addCase(deleteAccessoriesById.rejected, (state, actions) => {
        state.error = actions.payload;
      })
      .addCase(deleteAccessoriesById.fulfilled, (state, actions) => {
        state.message = actions.payload.message;
        state.data = state.data.filter(
          (card) => card._id !== actions.payload.id
        );
        state.error = null;
      })

      .addCase(editAccessoriesById.pending, (state, actions) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(editAccessoriesById.rejected, (state, actions) => {
        state.error = actions.payload;
        state.loading = false;
      })
      .addCase(editAccessoriesById.fulfilled, (state, actions) => {
        state.editAccessories = actions.payload;
        state.loading = false;
        state.error = null;
      });
  },
});

export const {
  clearCreatedAccessories,
  clearEditAccessories,
  clearCurrentAccessories,
} = accessoriesSlice.actions;

export default accessoriesSlice.reducer;
