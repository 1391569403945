import React from "react";
import styles from "./gobackbutton.module.scss";
import { useNavigate } from "react-router-dom";

const GoBackButton = ({path = -1}) => {
  const navigate = useNavigate();

  const navigationBack = () => {
      navigate(path);
  }

  return (
      <div
          className={styles.backIcon} onClick={() => navigationBack()}>
      </div>
      )
};

export default GoBackButton;
