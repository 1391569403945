import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTuningById } from "../../app/features/tuning.slice";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import MobileCardSlider from "../../components/MobileCardSlider/MobileCardSlider";
import PopupDetailedImage from "../../components/PopupDetailedImage/PopupDetailedImage";
import { config } from "../../config";
import styles from "./modding.module.scss";

const Modding = ({ openMenuLeft }) => {
  const [selectedCard, setSelectorCard] = useState({ isOpen: false });
  const { currentTuning } = useSelector((state) => state.tuning);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [detailedImagesContainer, setDetailedImagesContainer] = useState([]);

  const handleCardClick = (imageUrl, name = currentTuning.name) => {
    setSelectorCard({
      isOpen: true,
      image: `${config.proxy}/${imageUrl}`,
      name: name,
    });
  };

  useEffect(() => {
    if (currentTuning?.before && currentTuning?.after) {
      setDetailedImagesContainer([currentTuning?.before, currentTuning?.after]);
    }
  }, [currentTuning]);

  useEffect(() => {
    dispatch(getTuningById(id));
  }, [dispatch, id]);

  function handleCloseCardPopup() {
    setSelectorCard({ isOpen: false });
  }

  return (
    <main className={styles.watchPage}>
      <Helmet>
        <title>{`Lansoir - ${"Тюнинг"}`}</title>
      </Helmet>
      <PopupDetailedImage
        onClose={handleCloseCardPopup}
        isOpen={selectedCard.isOpen}
        card={selectedCard}
        detailedImagesContainer={detailedImagesContainer}
        url={config.proxy}
      />
      <div className={styles.watch}>
        <motion.div
          animate={{
            opacity: 1,
            y: 0,
            transition: { ease: "easeIn", duration: 0.4 },
          }}
          className={styles.info}
        ></motion.div>
        <div className={styles.watch__line}>
          <GoBackButton path={"/tuning"} />
          <div className={styles.watch__lineRight}>
            <div
              className={styles.watch__lineIcon}
              onClick={openMenuLeft}
            ></div>
            <p className={styles.watch__lineText}>Тюнинг</p>
          </div>
        </div>
        <div className={styles.watch__details}>
          <div
            style={{
              position: "relative",
              height: 500,
              overflow: "hidden",
              flex: 1,
            }}
          >
            {currentTuning?.before && (
              <ImageCarousel
                before={currentTuning?.before}
                after={currentTuning?.after}
                handleCardClick={handleCardClick}
              />
            )}
          </div>

          <div className={styles.watch__infoContainer}>
            <h1 className={styles.watch__detailsTitle}>
              {currentTuning?.name}
              <br />
            </h1>
            <p className={styles.watch__detailsSubtitle}>
              {currentTuning?.description}
            </p>
            {currentTuning?.price > 0 && (
              <p className={styles.watch__detailsPrice}>
                {currentTuning?.price
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                {currentTuning?.currency}
              </p>
            )}
          </div>
        </div>
      </div>
      {detailedImagesContainer.length && (
        <MobileCardSlider
          data={detailedImagesContainer}
          handleCardClick={handleCardClick}
        />
      )}
      <div className={styles.watch__mobInfoContainer}>
        <h3 className={styles.watch__mobDetailsTitle}>
          {currentTuning?.name}
          <br />
        </h3>
        <p className={styles.watch__mobDetailsSubtitle}>
          {currentTuning?.description}
        </p>
        {currentTuning?.price > 0 && (
          <p className={styles.watch__mobDetailsPrice}>
            {currentTuning?.price
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
            {currentTuning?.currency}
          </p>
        )}
      </div>
    </main>
  );
};

const ImageCarousel = ({ before, after, handleCardClick }) => {
  const [currentImage, setCurrentImage] = useState("before");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev === "before" ? "after" : "before"));
      setVisible(false);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleLoad = () => {
    setVisible(true);
  };

  return (
    <>
      <AnimatePresence>
        {currentImage === "before" && (
          <motion.img
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: "0%" }}
            exit={{ opacity: 0, y: "100%" }}
            transition={{ duration: 1, ease: "easeInOut" }}
            className={styles.watch__detailsImg}
            alt="тюнинг"
            src={`${config.proxy}/${before}`}
            onLoad={handleLoad}
            style={{ display: visible ? "block" : "none" }}
            onClick={() => handleCardClick(before)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {currentImage === "after" && (
          <motion.img
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: "0%" }}
            exit={{ opacity: 0, y: "100%" }}
            transition={{ duration: 1, ease: "easeInOut" }}
            className={styles.watch__detailsImg}
            alt="after"
            src={`${config.proxy}/${after}`}
            onLoad={handleLoad}
            style={{ display: visible ? "block" : "none" }}
            onClick={() => handleCardClick(before)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Modding;
