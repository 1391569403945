import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearEditAccessories,
  editAccessoriesById,
  getAccessoriesById,
} from "../../app/features/accessories.slice";
import BrandSelection from "../../components/BrandSelection/BrandSelection";
import CurrencySelection from "../../components/CurrencySelection/CurrencySelection";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import CustomRangeSize from "../../components/CustomRangeSize/CustomRangeSize";
import EditAccessoriesSlider from "../../components/EditAccessoriesSlider/EditAccessoriesSlider";
import GenderSwitch from "../../components/GenderSwitch/GenderSwitch";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import KitSelection from "../../components/KitSelection/KitSelection";
import CustomizedSnackbars from "../../components/Snackbar/Snackbar";
import WatchLoader from "../../components/WatchLoader/WatchLoader";
import { config } from "../../config";
import { handleInputNumberType } from "../../helper/helper";
import editIcons from "../../images/edit-btn.svg";
import styles from "./editaccessories.module.scss";

export default function EditAccessories() {
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [size, setSize] = useState("");
  const { currentAccessories, loading, editAccessories } = useSelector(
    (state) => state.accessories
  );

  const filesInputRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    isError: false,
  });

  const [formData, setFormData] = useState({
    brand: "",
    nameOfTheAccessories: "",
    kit: "",
    currency: "$",
    size: [],
    availability: false,
    mainAccessories: null,
    accessories: [],
    information: {
      description: {
        short: "",
        additional: "",
      },
    },
    price: "0",
    gender: "мужской",
  });

  const updateObject = (obj, keys, value) => {
    if (keys.length === 1) {
      obj[keys[0]] = value;
    } else {
      const [currentKey, ...restKeys] = keys;
      if (/\[\d+\]/.test(currentKey)) {
        const arrKey = currentKey.match(/^(.*?)(\[\d+\])$/);
        const arrayName = arrKey[1];
        const index = parseInt(arrKey[2].match(/\d+/)[0], 10);
        if (!obj[arrayName]) {
          obj[arrayName] = [];
        }
        obj[arrayName][index] = updateObject(
          obj[arrayName][index] || {},
          restKeys,
          value
        );
      } else {
        obj[currentKey] = updateObject(obj[currentKey] || {}, restKeys, value);
      }
    }
    return { ...obj };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = "";

    if (value === "true") updatedValue = true;
    else if (value === "false") updatedValue = false;
    else updatedValue = value;

    setFormData((prevFormData) => {
      const keys = name.trim().split(".");
      return updateObject({ ...prevFormData }, keys, updatedValue);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setMessage({ message: "", isError: false });
    }, 6000);

    if (!formData.brand)
      return setMessage({ message: "Выберите бренд", isError: true });
    if (formData.price === "0")
      return setMessage({
        message: "Добавьте цену",
        isError: true,
      });

    dispatch(
      editAccessoriesById({
        id,
        formData: {
          ...formData,
          size: size.trim() ? size.trim().split(" ") : [],
        },
      })
    );
    setEdit(true);
  };

  const handleChangeSize = (e) => {
    setSize(e.target.value);
  };

  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Удаляем пробелы из названия файла
      const fileNameWithoutSpaces = file.name.replace(/\s+/g, "");

      // Создаем новый файл с очищенным названием
      const fileWithCleanName = new File([file], fileNameWithoutSpaces, {
        type: file.type,
        lastModified: file.lastModified,
      });

      setFormData({
        ...formData,
        mainAccessories: fileWithCleanName,
      });
      const reader = new FileReader();
      reader.onload = (event) => {
        setMainImagePreview(event.target.result);
      };
      reader.readAsDataURL(fileWithCleanName);
    }
  };

  const handleImagesChange = (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const newImages = [...formData.accessories]; // Копируем существующий массив изображений
      const newImagesPreview = [...imagesPreview]; // Копируем существующий массив предварительных изображений

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileNameWithoutSpaces = file.name.replace(/\s+/g, "");
        const fileWithCleanName = new File([file], fileNameWithoutSpaces, {
          type: file.type,
          lastModified: file.lastModified,
        });
        newImages.push(fileWithCleanName);

        // Используем цикл для чтения каждого изображения
        const reader = new FileReader();
        reader.onload = (event) => {
          newImagesPreview.push(event.target.result); // Добавляем новое изображение в массив предварительных изображений
          setImagesPreview(newImagesPreview); // Обновляем состояние с новой копией массива предварительных изображений
        };
        reader.readAsDataURL(fileWithCleanName);
      }

      setFormData({
        ...formData,
        accessories: newImages, // Обновляем массив изображений с новыми файлами
      });
    }
  };

  useEffect(() => {
    dispatch(getAccessoriesById(id));
    if (editAccessories._id)
      setTimeout(() => {
        navigate(`/accessories/${editAccessories._id}`);
        dispatch(clearEditAccessories());
      }, 200);
  }, [dispatch, id, editAccessories._id, navigate]);

  useEffect(() => {
    if (currentAccessories) {
      const copiedFormData = JSON.parse(JSON.stringify(currentAccessories));
      setFormData({ ...copiedFormData, accessories: [] });
    }
    if (currentAccessories?.size?.length)
      setSize(currentAccessories?.size?.join(" "));
  }, [currentAccessories]);

  if (loading) return <WatchLoader />;

  return (
    <motion.main className={styles.accessoriesPage}>
      {/*
      <Header />
*/}
      <Helmet>
        <title>Lansoir - Редактирование Аксессуара</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <form
        className={styles.accessories}
        onSubmit={(e) => handleSubmit(e)}
        action="multipart/form-data"
      >
        <div className={styles.accessories__line}>
          <GoBackButton path={"/accessories"} />
          <div className={styles.accessories__lineRight}>
            <div className={styles.accessories__lineIcon}></div>
            <p className={styles.accessories__lineText}>Аксессуар</p>
          </div>
        </div>
        <div className={styles.accessories__details}>
          <div className={styles.mainImg} style={{ position: "relative" }}>
            {mainImagePreview || formData.mainImage ? (
              <>
                <label
                  htmlFor="fileInput-accessories-edit-mainImage"
                  className={styles.editFile}
                >
                  <div className={styles.customInputText}>
                    <img src={editIcons} alt="" />
                  </div>
                </label>
                <img
                  src={
                    mainImagePreview ||
                    `${config.proxy}/${currentAccessories.mainImage}`
                  }
                  alt="Selected Accessories"
                  className={styles.accessories__detailsImg}
                />
              </>
            ) : (
              <label
                htmlFor="fileInput-accessories-edit-mainImage"
                className={styles.customInputLabel}
                style={{ height: "100%", cursor: "pointer" }}
              >
                <div className={styles.customInputText}>+</div>
              </label>
            )}
            <input
              type="file"
              id="fileInput-accessories-edit-mainImage"
              name="mainAccessories"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                handleMainImageChange(e);
              }}
            />
          </div>
          <div className={styles.accessories__infoContainer}>
            <h3 className={styles.accessories__detailsTitle}>
              <BrandSelection
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                type={"isAccessories"}
              />
            </h3>

            <div className={styles.accessories__detailsTitle_subtitle}>
              <KitSelection
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
              />
            </div>
            <input
              type="text"
              name="nameOfTheAccessories"
              className={styles.nameOfTheAccessories}
              placeholder="Введите название ювелирки"
              id="nameOfTheAccessories"
              value={formData.nameOfTheAccessories || ""}
              onChange={handleChange}
            />
            <GenderSwitch formData={formData} handleChange={handleChange} />
            <textarea
              className={styles.accessories__detailsSubtitle}
              placeholder="Введите описание"
              name="information.description.short"
              value={formData.information?.description?.short || ""}
              rows={4}
              onChange={(e) => handleChange(e)}
            />
            <div className={styles.accessories__detailsPrice}>
              <input
                type="text"
                value={
                  formData.price
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""
                }
                placeholder="Введите цену"
                name="price"
                id="price"
                onChange={(e) => handleChange(e)}
                onInput={(e) => handleInputNumberType(e)}
              />
              <CurrencySelection
                formData={formData}
                handleChange={handleChange}
              />
            </div>
            <div className={styles.accessories__size}>
              <input
                type="text"
                value={size || ""}
                placeholder="Если у изделия есть размеры введите их через пробел"
                name="size"
                onChange={handleChangeSize}
              />
            </div>
            <CustomRangeSize size={size?.trim().split(" ")} />
            <CustomCheckbox formData={formData} handleChange={handleChange} />
          </div>
        </div>
        <EditAccessoriesSlider
          formData={formData}
          handleImagesChange={handleImagesChange}
          imagesPreview={imagesPreview}
          setFormData={setFormData}
          setImagesPreview={setImagesPreview}
          filesInputRef={filesInputRef}
        />
        <div className={styles.accessories__info}>
          <textarea
            className={styles.accessories__detailsSubtitle}
            name="information.description.additional"
            placeholder="Введите дополнительную информацию если она имеется"
            id="information.description.additional"
            value={formData.information?.description?.additional || ""}
            onChange={handleChange}
            rows="7"
            style={{ width: "100%" }}
          ></textarea>
        </div>
        <div className={styles.submitBtn}>
          <button
            disabled={edit || message.isError}
            type="submit"
            className={styles.submit}
          >
            <span>Сохранить</span>
            {edit && <CircularProgress color="success" />}
          </button>
        </div>
        <CustomizedSnackbars msg={message} />
      </form>
      {/*
      <Footer />
*/}
    </motion.main>
  );
}
