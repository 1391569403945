import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";
import styles from "./WatchsSlider.module.scss";

export default function WatchsSlider({ data }) {
  const [sliderCount, setSliderCount] = useState(0);

  const sliderContainerRef = useRef(null);
  const [sliderContainerWidth, setSliderContainerWidth] = useState(0);

  const navigate = useNavigate();

  const handleNavigateProductPage = (product, id) => {
    if (product === "часы") navigate(`/watches/${id}`);
    if (product === "аксессуары") navigate(`/accessories/${id}`);
    else if (product === "ювелирные изделия") navigate(`/jewelries/${id}`);
  };

  let sliderItemWidth = `${sliderContainerWidth / 4}px`;
  /*
  let sliderItemWidth = `${(sliderContainerWidth - 300) / 4}px`;
*/
  useEffect(() => {
    if (sliderContainerRef.current) {
      setSliderContainerWidth(sliderContainerRef.current.offsetWidth);
    }
  }, [data]);

  function prevSlider() {
    setSliderCount((prevCount) =>
      prevCount > 0 ? prevCount - 1 : data.length - 1
    );
  }

  function nextSlider() {
    setSliderCount((prevCount) =>
      prevCount < data.length - 1 ? prevCount + 1 : 0
    );
  }

  return (
    <div className={`${styles.watchesSlider}`}>
      <div className={styles.watchesSlider__container}>
        <div className={styles.watchesSlider__list}>
          <div
            ref={sliderContainerRef}
            className={styles.watchesSlider__containerSlider}
            style={{ transform: `translateX(${-sliderCount * (100 / 4)}%)` }}
          >
            {data.map((watch, index) => (
              <div
                key={index}
                className={styles.watchesSlider__watch}
                style={{ minWidth: sliderItemWidth }}
                onClick={() =>
                  handleNavigateProductPage(watch.product, watch._id)
                }
              >
                <img
                  className={styles.watchesSlider__watchImage}
                  src={
                    watch.mainImage ? `${config.proxy}/${watch.mainImage}` : ""
                  }
                  alt={watch.imageUrl}
                />
                <div className={styles.watchesSlider__watchInfo}>
                  <h4 className={styles.watchesSlider__watchName}>
                    {watch.brand.nameOfTheBrand}
                    <span className={styles.watchesSlider__watchName_subname}>
                      {watch.kit}
                    </span>
                  </h4>
                  <p className={styles.watchesSlider__watchDiscription}>
                    {watch?.information?.description?.short}
                  </p>
                  <p className={styles.watchesSlider__watchPrice}>
                    {watch.price > 0 &&
                      watch.price
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
                        " " +
                        watch.currency}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={styles.watchesSlider__btnLeft}
          onClick={prevSlider}
        ></div>
        <div
          className={styles.watchesSlider__btnRight}
          onClick={nextSlider}
        ></div>
      </div>
    </div>
  );
}
