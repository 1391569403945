import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  data: [],
  error: null,
  loading: false,
};

export const getProducts = createAsyncThunk(
  "get/products",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${config.proxy}/products`);
      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, actions) => {
        state.data = actions.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(getProducts.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProducts.rejected, (state, actions) => {
        state.error = actions.payload;
        state.loading = false;
      });
  },
});

export default productSlice.reducer;
