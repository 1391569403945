import styles from "./MobileCardSlider.module.scss";

import { useEffect, useRef, useState } from "react";
import { config } from "../../config";

export default function MobileCardSlider({ data, isUrl = true }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [sliderCount, setSliderCount] = useState(0);
  const sliderContainerRef = useRef(null);
  const sliderItemWidth = `${width}px`;
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  useEffect(() => {
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function prevSlider() {
    setSliderCount((prevCount) =>
      prevCount > 0 ? prevCount - 1 : data.length - 1
    );
  }

  function nextSlider() {
    setSliderCount((prevCount) =>
      prevCount < data.length - 1 ? prevCount + 1 : 0
    );
  }

  function handleTouchStart(event) {
    setTouchStartX(event.touches[0].clientX);
  }

  function handleTouchMove(event) {
    setTouchEndX(event.touches[0].clientX);
  }

  function handleTouchEnd(event) {
    if (touchEndX < touchStartX) {
      nextSlider();
    } else if (touchEndX > touchStartX) {
      prevSlider();
    }
  }

  return (
    <div
      className={styles.mobileCardSlider}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className={styles.mobileCardSlider__container}
        ref={sliderContainerRef}
      >
        <div className={styles.mobileCardSlider__list}>
          <div
            className={styles.mobileCardSlider__containerSlider}
            style={{
              transform: `translateX(${-sliderCount * 100}%)`,
              width: sliderItemWidth,
            }}
          >
            {data?.map((item, index) => (
              <div style={{ width: sliderItemWidth }} key={index}>
                <img
                  src={`${isUrl ? config.proxy + "/" + item : item}`}
                  alt="watch"
                  className={styles.mobileCardSlider__image}
                  style={{ width: sliderItemWidth }}
                />
              </div>
            ))}
          </div>
        </div>
        {data.length > 1 && (
          <>
            <div
              className={styles.mobileCardSlider__btnLeft}
              onClick={prevSlider}
            ></div>
            <div
              className={styles.mobileCardSlider__btnRight}
              onClick={nextSlider}
            ></div>
          </>
        )}
      </div>
      <ul className={styles.mobileCardSlider__containerCircle}>
        {data?.map((item, index) => (
          <li
            className={`${styles.mobileCardSlider__circle} ${
              index === sliderCount
                ? styles.mobileCardSlider__circle_active
                : ""
            }`}
            key={index}
          />
        ))}
      </ul>
    </div>
  );
}
