import styles from "./createaccessories.module.scss";

import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addAccessories,
  clearCreatedAccessories,
} from "../../app/features/accessories.slice";
import BrandSelection from "../../components/BrandSelection/BrandSelection";
import CreateAccessoriesSlider from "../../components/CreateAccessoriesSlider/CreateAccessoriesSlider";
import CurrencySelection from "../../components/CurrencySelection/CurrencySelection";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import CustomRangeSize from "../../components/CustomRangeSize/CustomRangeSize";
import GenderSwitch from "../../components/GenderSwitch/GenderSwitch";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import KitSelection from "../../components/KitSelection/KitSelection";
import CustomizedSnackbars from "../../components/Snackbar/Snackbar";
import { handleInputNumberType } from "../../helper/helper";
import editIcons from "../../images/edit-btn.svg";

export default function CreateAccessories() {
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const [imagesPreview, setImagesPreview] = useState([]);
  const filesInputRef = useRef();
  const dispatch = useDispatch();
  const [size, setSize] = useState("");
  const [create, setCreate] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    isError: false,
  });

  const navigate = useNavigate();

  const { createdAccessories } = useSelector((state) => state.accessories);

  const [formData, setFormData] = useState({
    brand: null,
    nameOfTheAccessories: "",
    kit: "",
    size: [],
    availability: false,
    mainAccessories: null,
    accessories: [],
    currency: "$",
    information: {
      description: {
        short: "",
        additional: "",
      },
    },
    price: "0",
    gender: "мужской",
  });
  const updateObject = (obj, keys, value) => {
    if (keys.length === 1) {
      obj[keys[0]] = value;
    } else {
      const [currentKey, ...restKeys] = keys;
      if (/\[\d+\]/.test(currentKey)) {
        const arrKey = currentKey.match(/^(.*?)(\[\d+\])$/);
        const arrayName = arrKey[1];
        const index = parseInt(arrKey[2].match(/\d+/)[0], 10);
        if (!obj[arrayName]) {
          obj[arrayName] = [];
        }
        obj[arrayName][index] = updateObject(
          obj[arrayName][index] || {},
          restKeys,
          value
        );
      } else {
        obj[currentKey] = updateObject(obj[currentKey] || {}, restKeys, value);
      }
    }
    return { ...obj };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = "";

    if (value === "true") updatedValue = true;
    else if (value === "false") updatedValue = false;
    else updatedValue = value;

    setFormData((prevFormData) => {
      const keys = name.trim().split(".");
      return updateObject({ ...prevFormData }, keys, updatedValue);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setMessage({ message: "", isError: false });
    }, 6000);

    if (!formData.brand)
      return setMessage({ message: "Выберите бренд", isError: true });
    if (!formData.information.description.short)
      return setMessage({
        message: "Заполните поле с кратким описанием",
        isError: true,
      });
    if (!formData.mainAccessories)
      return setMessage({
        message: "Добавьте Главное изображение",
        isError: true,
      });
    dispatch(
      addAccessories({
        ...formData,
        size: size.trim() ? size.trim().split(" ") : [],
      })
    );
    setCreate(true);
  };

  const handleChangeSize = (e) => {
    setSize(e.target.value);
  };

  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Удаляем пробелы из названия файла
      const fileNameWithoutSpaces = file.name.replace(/\s+/g, "");

      // Создаем новый файл с очищенным названием
      const fileWithCleanName = new File([file], fileNameWithoutSpaces, {
        type: file.type,
        lastModified: file.lastModified,
      });

      setFormData({
        ...formData,
        mainAccessories: fileWithCleanName,
      });
      const reader = new FileReader();
      reader.onload = (event) => {
        setMainImagePreview(event.target.result);
      };
      reader.readAsDataURL(fileWithCleanName);
    }
  };

  const handleImagesChange = (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const newImages = [...formData.accessories]; // Копируем существующий массив изображений
      const newImagesPreview = [...imagesPreview]; // Копируем существующий массив предварительных изображений

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileNameWithoutSpaces = file.name.replace(/\s+/g, "");
        const fileWithCleanName = new File([file], fileNameWithoutSpaces, {
          type: file.type,
          lastModified: file.lastModified,
        });
        newImages.push(fileWithCleanName);

        // Используем цикл для чтения каждого изображения
        const reader = new FileReader();
        reader.onload = (event) => {
          newImagesPreview.push(event.target.result); // Добавляем новое изображение в массив предварительных изображений
          setImagesPreview(newImagesPreview); // Обновляем состояние с новой копией массива предварительных изображений
        };
        reader.readAsDataURL(fileWithCleanName);
      }

      setFormData({
        ...formData,
        accessories: newImages, // Обновляем массив изображений с новыми файлами
      });
    }
  };

  useEffect(() => {
    if (createdAccessories._id)
      setTimeout(() => {
        navigate(`/accessories/${createdAccessories._id}`);
        dispatch(clearCreatedAccessories());
      }, 200);
  }, [dispatch, createdAccessories._id, navigate]);

  return (
    <motion.main className={styles.accessoriesPage}>
      <Helmet>
        <title>Lansoir - Создание Аксессуаров</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <form
        className={styles.accessories}
        onSubmit={(e) => handleSubmit(e)}
        action="multipart/form-data"
      >
        <div className={styles.accessories__line}>
          <GoBackButton path={"/accessories"} />
          <div className={styles.accessories__lineRight}>
            <div className={styles.accessories__lineIcon}></div>
            <p className={styles.accessories__lineText}>Аксессуары</p>
          </div>
        </div>
        <div className={styles.accessories__details}>
          <div className={styles.mainImg} style={{ position: "relative" }}>
            {mainImagePreview ? (
              <>
                <label
                  htmlFor="fileInput-accessories-mainImage"
                  className={styles.editFile}
                >
                  <div className={styles.customInputText}>
                    <img src={editIcons} alt="" />
                  </div>
                </label>
                <img
                  src={mainImagePreview}
                  alt="Selected Accessories"
                  className={styles.accessories__detailsImg}
                />
              </>
            ) : (
              <label
                htmlFor="fileInput-accessories-mainImage"
                className={styles.customInputLabel}
                style={{ height: "100%", cursor: "pointer" }}
              >
                <div className={styles.customInputText}>+</div>
              </label>
            )}
            <input
              type="file"
              id="fileInput-accessories-mainImage"
              name="mainAccessories"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                handleMainImageChange(e);
              }}
            />
          </div>
          <div className={styles.accessories__infoContainer}>
            <h3 className={styles.accessories__detailsTitle}>
              <BrandSelection
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                type={"isAccessories"}
              />
            </h3>
            {/* <div>
              <ProductSelection
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
              />
            </div> */}
            <div className={styles.accessories__detailsTitle_subtitle}>
              <KitSelection
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
              />
            </div>
            <input
              type="text"
              name="nameOfTheAccessories"
              className={styles.nameOfTheAccessories}
              placeholder="Введите название ювелирки"
              id="nameOfTheAccessories"
              value={formData.nameOfTheAccessories}
              onChange={handleChange}
            />
            <GenderSwitch formData={formData} handleChange={handleChange} />
            <textarea
              className={styles.accessories__detailsSubtitle}
              placeholder="Введите описание"
              name="information.description.short"
              value={formData.information?.description.short}
              onChange={(e) => handleChange(e)}
              rows={4}
            />
            <div className={styles.accessories__detailsPrice}>
              <input
                type="text"
                value={formData.price}
                placeholder="Введите цену"
                name="price"
                id="price"
                onChange={(e) => handleChange(e)}
                onInput={(e) => handleInputNumberType(e)}
              />
              <CurrencySelection
                formData={formData}
                handleChange={handleChange}
              />
            </div>
            <div className={styles.accessories__size}>
              <input
                type="text"
                value={size}
                placeholder="Если у изделия есть размеры введите их через пробел"
                name="size"
                onChange={handleChangeSize}
              />
            </div>
            <CustomRangeSize size={size.trim().split(" ")} />
            <CustomCheckbox formData={formData} handleChange={handleChange} />
          </div>
        </div>
        <CreateAccessoriesSlider
          formData={formData}
          handleImagesChange={handleImagesChange}
          imagesPreview={imagesPreview}
          setFormData={setFormData}
          setImagesPreview={setImagesPreview}
          filesInputRef={filesInputRef}
        />
        <div className={styles.accessories__info}>
          <textarea
            className={styles.accessories__detailsSubtitle}
            name="information.description.additional"
            placeholder="Введите дополнительную информацию если она имеется"
            id="information.description.additional"
            value={formData.information.description.additional}
            onChange={handleChange}
            rows="5"
            style={{ width: "100%" }}
          ></textarea>
        </div>
        <div className={styles.submitBtn}>
          <button
            disabled={create || message.isError}
            type="submit"
            className={styles.submit}
          >
            <span>Сохранить</span>
            {create && <CircularProgress color="success" />}
          </button>
        </div>
      </form>
      <CustomizedSnackbars msg={message} />
    </motion.main>
  );
}
