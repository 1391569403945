import React, { useEffect, useState } from "react";
import styles from "./genderswitch.module.scss";

const GenderSwitch = ({ formData, handleChange }) => {
  const [check, setChecked] = useState(true);

  useEffect(() => {
    if (formData?.gender === "мужской") setChecked(true);
    else setChecked(false);
  }, [formData?.gender]);

  return (
    <div className={styles.container}>
      <input
        id="gender-switch"
        type="checkbox"
        hidden
        value={!check ? "мужской" : "женский"}
        onChange={(e) => {
          setChecked(!check);
          handleChange(e);
        }}
        checked={check}
        name="gender"
      />
      <label htmlFor="gender-switch" className={styles.switch}>
        <h2 className={styles.female}>✝</h2>
        <h2 className={styles.male}>➜</h2>
      </label>
    </div>
  );
};

export default GenderSwitch;
