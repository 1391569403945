import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  data: [],
  recommendation: [],
  product: "",
  loading: false,
  error: null,
  filterRequest: false,
  novelty: [],
};

export const getFilterData = createAsyncThunk(
  "get/filter",
  async (filterData = {}, thunkAPI) => {
    try {
      const response = await axios.post(`${config.proxy}/all`, filterData);

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getNovelty = createAsyncThunk(
  "get/novelty",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${config.proxy}/all/novelty`);

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    addProduct: (state, actions) => {
      state.product = actions.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilterData.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
        state.filterRequest = true;
      })
      .addCase(getFilterData.rejected, (state, actions) => {
        state.error = actions.payload;
        state.loading = false;
      })
      .addCase(getFilterData.fulfilled, (state, actions) => {
        state.data = actions.payload;
        state.loading = false;
        state.error = null;
        state.filterRequest = false;
      })

      .addCase(getNovelty.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNovelty.rejected, (state, actions) => {
        state.error = actions.payload;
        state.loading = false;
      })
      .addCase(getNovelty.fulfilled, (state, actions) => {
        state.novelty = actions.payload;
        state.error = null;
        state.loading = false;
      });
  },
});

export const { addProduct } = filterSlice.actions;

export default filterSlice.reducer;
