import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  data: [],
  currentBrand: {},
  loading: true,
  error: null,
  msg: {},
};

export const getBrands = createAsyncThunk("get/brands", async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${config.proxy}/brands`);
    const data = response.data;

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getBrandById = createAsyncThunk(
  "get/brandById",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${config.proxy}/brand/${id}`);
      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addBrand = createAsyncThunk(
  "add/brand",
  async ({ formData }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(`${config.proxy}/brand`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: "Отправка данных прошла не успешно код ошибки: 500",
      });
    }
  }
);

export const editBrand = createAsyncThunk(
  "edit/brand",
  async ({ id, formData }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.patch(
        `${config.proxy}/brand/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: "Ошибка при изменении данных",
      });
    }
  }
);

export const deleteBrandById = createAsyncThunk(
  "delete/brandById",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.delete(`${config.proxy}/brand/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.pending, (state, actions) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getBrands.rejected, (state, actions) => {
        state.error = actions.error;
        state.loading = false;
      })
      .addCase(getBrands.fulfilled, (state, actions) => {
        state.data = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(getBrandById.pending, (state, actions) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getBrandById.rejected, (state, actions) => {
        state.error = actions.error;
        state.loading = false;
      })
      .addCase(getBrandById.fulfilled, (state, actions) => {
        state.currentBrand = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(deleteBrandById.pending, (state, actions) => {
        state.error = null;
        state.loading = true;
        state.msg = {};
      })
      .addCase(deleteBrandById.rejected, (state, actions) => {
        state.error = actions.error;
        state.loading = false;
        state.msg = {};
      })
      .addCase(deleteBrandById.fulfilled, (state, actions) => {
        state.msg = actions.payload;
        state.data = state.data.filter(
          (item) => item._id !== actions.payload.id
        );
        state.loading = false;
        state.error = null;
      })

      .addCase(addBrand.pending, (state, actions) => {
        state.loading = true;
        state.error = false;
        state.msg = {};
      })
      .addCase(addBrand.rejected, (state, actions) => {
        state.loading = false;
        state.error = actions.error;
        state.msg = {};
      })
      .addCase(addBrand.fulfilled, (state, actions) => {
        state.data.push(actions.payload);
        state.error = null;
        state.loading = false;
        state.msg = actions.payload;
      })

      .addCase(editBrand.pending, (state, actions) => {
        state.loading = true;
        state.error = false;
        state.msg = {};
      })
      .addCase(editBrand.rejected, (state, actions) => {
        state.loading = false;
        state.error = actions.error;
        state.msg = {};
      })
      .addCase(editBrand.fulfilled, (state, actions) => {
        state.error = null;
        state.loading = false;
        state.msg = actions.payload.msg;
        state.data = state.data.map((item) => {
          if (item._id === actions.payload.brand._id) {
            return actions.payload.brand;
          }
          return item;
        });
      });
  },
});

export default brandSlice.reducer;
