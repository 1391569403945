import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  data: [],
  error: null,
  loading: false,
};

export const getCurrencies = createAsyncThunk(
  "get/currencies",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${config.proxy}/currency`);
      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencies.fulfilled, (state, actions) => {
        state.data = actions.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(getCurrencies.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrencies.rejected, (state, actions) => {
        state.error = actions.payload;
        state.loading = false;
      });
  },
});

export default currencySlice.reducer;
