import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import HeaderSubNav from "../HeaderSubNav/HeaderSubNav";
import profileIcon from "./../../images/profile.svg";
import styles from "./Header.module.scss";

export default function Header({ openMenuLeft, openAdminPanel, firstVisit }) {
  const admin = localStorage.getItem("admin");
  const location = useLocation();

  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  let signIn = useSelector((state) => state.application.signIn);

  const isMainPage = location.pathname === "/";

  const animateLansoirLogo = {
    initial: {
      width: 126,
      height: 165,
      top: "calc(50vh - 80px)",
      left: "50%",
    },
    animate: {
      width: 24,
      height: 30,
      top: 30,
      transition: { delay: 2, duration: 1 },
    },
  };
  useEffect(() => {}, [isMainPage]);
  useEffect(() => {}, [signIn]);

  function handleScroll() {
    const currentScrollPos = window.pageYOffset;
    if (prevScrollPos < currentScrollPos) {
      // скрол вниз
      document.querySelector(`.${styles.header}`).classList.add(styles.hidden);
    } else {
      // скрол верх
      document
        .querySelector(`.${styles.header}`)
        .classList.remove(styles.hidden);
    }
    setPrevScrollPos(currentScrollPos);
  }
  useEffect(() => {
    if (window.innerWidth > 767) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [prevScrollPos]);

  return (
    <>
      <motion.header
        animate={{
          opacity: 1,
          transition: { duration: 1, delay: 1 },
        }}
        className={`${styles.header}`}
      >
        <NavLink to={"/"} className={styles.header__logoBlock}>
          <div className={styles.header__logoIcon}></div>
          <p className={styles.header__iconText}>LANSOIR</p>
        </NavLink>
        <nav className={styles.header__nav}>
          <ul className={styles.header__list}>
            <li className={styles.header__item}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.header__item_active : styles.header__item
                }
                to={"/watches"}
              >
                ЧАСЫ
              </NavLink>
            </li>
            <li className={styles.header__item}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.header__item_active : styles.header__item
                }
                to={"/jewelries"}
              >
                ЮВЕЛИРНЫЕ УКРАШЕНИЯ
              </NavLink>
            </li>
            <li className={styles.header__item}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.header__item_active : styles.header__item
                }
                to={"/accessories"}
              >
                АКСЕССУАРЫ
              </NavLink>
            </li>
            <li className={styles.header__item}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.header__item_active : styles.header__item
                }
                to={"/news"}
              >
                БЛОГ/НОВОСТИ
              </NavLink>
            </li>
            <li className={styles.header__item}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.header__item_active : styles.header__item
                }
                to={"/tuning"}
              >
                ТЮНИНГ
              </NavLink>
            </li>
            <li className={styles.header__item}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.header__item_active : styles.header__item
                }
                to={"/service"}
              >
                СЕРВИС
              </NavLink>
            </li>
            {(signIn || admin) && (
              <li
                className={styles.header__item}
                onClick={() => openAdminPanel()}
              >
                <img
                  className={styles.header__adminIcon}
                  src={profileIcon}
                  alt=""
                />
              </li>
            )}
          </ul>
        </nav>
      </motion.header>
      <motion.header className={styles.headerMob}>
        <nav className={styles.headerMob__nav}>
          <motion.div
            initial={isMainPage ? { opacity: 0 } : {}}
            animate={
              isMainPage
                ? { opacity: 1, transition: { delay: 3, duration: 1 } }
                : {}
            }
            className={styles.headerMob__menuIcon}
            onClick={() => openMenuLeft()}
          ></motion.div>
          <NavLink to={"/"}>
            <motion.div
              initial={isMainPage ? animateLansoirLogo.initial : {}}
              animate={isMainPage ? animateLansoirLogo.animate : {}}
              className={styles.headerMob__headerLogo}
            ></motion.div>
          </NavLink>
          <motion.div
            initial={isMainPage ? { opacity: 0 } : {}}
            animate={
              isMainPage
                ? { opacity: 1, transition: { delay: 3, duration: 1 } }
                : {}
            }
            className={styles.headerMob__contactsBlock}
          >
            <div className={styles.headerMob__tel}>
              <Link
                href="tel:+78008008080"
                style={{
                  width: "15px",
                  height: "15px",
                  display: "inline-block",
                }}
              ></Link>
            </div>
            {/*Не удалять !!
             <NavLink to={"/location"}>
              <div className={styles.headerMob__location}></div>
            </NavLink> */}
          </motion.div>
        </nav>
        <motion.div
          initial={isMainPage ? { opacity: 0 } : {}}
          animate={
            isMainPage
              ? { opacity: 1, transition: { delay: 3, duration: 1 } }
              : {}
          }
        >
          <HeaderSubNav />
        </motion.div>
      </motion.header>
    </>
  );
}
