import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Element } from "react-scroll";

import {
  clearCurrentAccessories,
  getAccessoriesById,
  getRecommendation,
} from "../../app/features/accessories.slice";
import CustomRangeSize from "../../components/CustomRangeSize/CustomRangeSize";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import MobileCardSlider from "../../components/MobileCardSlider/MobileCardSlider";
import PopupDetailedImage from "../../components/PopupDetailedImage/PopupDetailedImage";
import WatchLoader from "../../components/WatchLoader/WatchLoader";
import WatchSlider from "../../components/WatchSlider/WatchSlider";
import WatchsSlider from "../../components/WatchsSlider/WatchsSlider";
import { config } from "../../config";
import editIcon from "../../images/edit-btn.svg";
import NotFound from "../NotFound/NotFound";
import styles from "./Accessories.module.scss";

export default function Accessories({ openMenuLeft }) {
  const dispatch = useDispatch();
  const [selectedCard, setSelectorCard] = useState({ isOpen: false });
  const [detailedImagesContainer, setDetailedImagesContainer] = useState([]);
  const [mainImageLoading, setMainImageLoading] = useState(false);
  const navigate = useNavigate();
  const [checkSize, setCheckSize] = useState(false);

  const accessories = useSelector((state) => state.accessories);
  const admin = localStorage.getItem("admin");

  const recommendation = accessories.recommendation;
  const loading = accessories.loading;
  const error = accessories.error;

  const {
    _id,
    brand,
    images,
    information,
    mainImage,
    nameOfTheAccessories,
    price,
    kit,
    currency,
    size,
  } = accessories.currentAccessories;

  const { id } = useParams();
  const { pathname } = useLocation();
  useEffect(() => {
    if (Boolean(accessories.currentAccessories.images)) {
      setDetailedImagesContainer([mainImage, ...images]);
    }
  }, [
    accessories.currentAccessories?.images,
    accessories.currentWatch?.mainImage,
  ]);

  const handleNavigateEditAccessoriesPage = () => {
    navigate(`/accessories/admin/edit/${_id}`);
  };

  const handleCardClick = (imageUrl, name = nameOfTheAccessories) => {
    setSelectorCard({
      isOpen: true,
      image: `${config.proxy}/${imageUrl}`,
      name: name,
    });
  };
  function handleCloseCardPopup() {
    setSelectorCard({ isOpen: false });
  }

  useEffect(() => {
    dispatch(getAccessoriesById(id));
    return () => {
      dispatch(clearCurrentAccessories());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (window.innerWidth > 767 && brand?._id) {
      dispatch(getRecommendation({ id, brand: brand?._id }));
    }
  }, [dispatch, id, brand?._id]);

  if (!_id && error) return <NotFound />;

  return (
    <motion.main className={styles.accessoriesPage}>
      <Helmet>
        <title>{`Lansoir - ${brand?.nameOfTheBrand || "Аксессуары"} ${
          kit || ""
        }`}</title>
      </Helmet>
      <PopupDetailedImage
        onClose={handleCloseCardPopup}
        isOpen={selectedCard.isOpen}
        card={selectedCard}
        detailedImagesContainer={detailedImagesContainer}
        url={config.proxy}
      />
      <div className={styles.accessories}>
        {loading ? (
          <WatchLoader />
        ) : (
          <>
            <motion.div
              animate={{
                opacity: 1,
                y: 0,
                transition: { ease: "easeIn", duration: 0.4 },
              }}
              className={styles.info}
            >
              <div className={styles.accessories__line}>
                <GoBackButton path={"/accessories"} />
                <div className={styles.accessories__lineRight}>
                  <div
                    className={styles.accessories__lineIcon}
                    onClick={openMenuLeft}
                  ></div>
                  <p className={styles.accessories__lineText}>
                    Аксессуары
                  </p>
                </div>
              </div>
              <div className={styles.accessories__details}>
                {mainImage && (
                  <>
                    <img
                      src={`${config.proxy}/${mainImage}`}
                      alt="accessories"
                      className={styles.accessories__detailsImg}
                      onClick={() => handleCardClick(mainImage)}
                      onLoad={() => setMainImageLoading(true)}
                      style={{ display: mainImageLoading ? "block" : "none" }}
                    />
                    {!mainImageLoading && (
                      <div
                        style={{
                          minWidth: 255,
                          height: 500,
                          backgroundColor: "#555",
                        }}
                      ></div>
                    )}
                  </>
                )}
                <div className={styles.accessories__infoContainer}>
                  {admin && (
                    <img
                      src={editIcon}
                      alt=""
                      onClick={handleNavigateEditAccessoriesPage}
                      style={{
                        width: 24,
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    />
                  )}
                  <h1 className={styles.accessories__detailsTitle}>
                    {brand?.nameOfTheBrand}
                    <br />
                    <span className={styles.accessories__detailsTitle_subtitle}>
                      {kit}
                    </span>
                  </h1>
                  <p className={styles.accessories__detailsSubtitle}>
                    {information?.description.short}
                  </p>
                  {price > 0 && (
                    <p className={styles.accessories__detailsPrice}>
                      {price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      {currency}
                    </p>
                  )}
                  {size?.length > 0 ? (
                    <div style={{ minHeight: 201 }}>
                      <p
                        className={styles.accessories__detailsSizeTitle}
                        onClick={() => setCheckSize(true)}
                      >
                        выбрать размер
                      </p>
                      {checkSize && <CustomRangeSize size={size} />}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {images !== undefined && images.length > 0 && (
                <WatchSlider data={images} handleCardClick={handleCardClick} />
              )}
              <MobileCardSlider
                data={detailedImagesContainer}
                handleCardClick={handleCardClick}
              />
              <div className={styles.accessories__mobInfoContainer}>
                <h3 className={styles.accessories__mobDetailsTitle}>
                  {brand?.nameOfTheBrand}
                  <br />
                  <span
                    className={styles.accessories__mobDetailsTitle_subtitle}
                  >
                    {kit}
                  </span>
                </h3>
                <p className={styles.accessories__mobDetailsSubtitle}>
                  {information?.description.short}
                </p>
                {price > 0 && (
                  <p className={styles.accessories__mobDetailsPrice}>
                    {price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {}
                  </p>
                )}
                {size?.length > 0 && <CustomRangeSize size={size} />}
              </div>
              <div className={styles.accessories__info}>
                <p className={styles.accessories__infoText}>
                  {information?.description.additional}
                </p>
              </div>
            </motion.div>
            <div className={styles.accessories__lineFooterInfo}></div>
            {recommendation.length > 0 && (
              <WatchsSlider data={recommendation} />
            )}
          </>
        )}
        <Element name="targetElement">
          <a href="tel:+78008008080" className={styles.accessories__btnPhone}>
            <p className={styles.accessories__btnPhoneText}>СВЯЗАТЬСЯ</p>
          </a>
        </Element>
      </div>
      {/*<div className={styles.accessories__sliderArrow} />*/}
    </motion.main>
  );
}
