import { useEffect, useRef, useState } from "react";
import deleteIcon from "../../images/watchCard/delete-icons.svg";
import styles from "./CreateAccessoriesSlider.module.scss";

export default function CreateAccessoriesSlider({
  formData,
  handleImagesChange,
  imagesPreview,
  setFormData,
  setImagesPreview,
  filesInputRef,
  currentAccessories,
}) {
  const [sliderCount, setSliderCount] = useState(0);

  const sliderContainerRef = useRef(null);
  const [sliderContainerWidth, setSliderContainerWidth] = useState(0);

  const sliderItemWidth = `${sliderContainerWidth / 3}px`;

  useEffect(() => {
    if (sliderContainerRef.current) {
      setSliderContainerWidth(sliderContainerRef.current.offsetWidth);
    }
  }, [formData.accessories]);

  function prevSlider() {
    setSliderCount((prevCount) =>
      prevCount > 0 ? prevCount - 1 : formData.accessories.length - 1
    );
  }

  function nextSlider() {
    setSliderCount((prevCount) =>
      prevCount < formData.accessories.length - 1 ? prevCount + 1 : 0
    );
  }

  const handleDeleteImage = (index, setImagesPreview, setFormData) => {
    const updatedFormData = formData.accessories?.filter((item, i) => index !== i);
    const updatedImagesPreview = imagesPreview.filter((item, i) => index !== i);
    setFormData({ ...formData, accessories: updatedFormData });
    setImagesPreview(updatedImagesPreview);
  };

  const handleDeleteImages = (setImagesPreview, setFormData) => {
    setFormData({ ...formData, accessories: [] });
    setImagesPreview([]);
    filesInputRef.current.value = null;
  };

  return (
    <div className={styles.sliderAccessories}>
      <div className={styles.sliderAccessories__container} ref={sliderContainerRef}>
        <div className={styles.sliderAccessories__list}>
          <div
            className={styles.sliderAccessories__containerSlider}
            style={{
              transform: `translateX(${-sliderCount * (100 / 3)}%)`,
            }}
          >
            {currentAccessories &&
              currentAccessories?.images?.map((item, index) => (
                <div style={{ position: "relative" }} key={index}>
                  <span
                    style={{
                      position: "absolute",
                      right: 5,
                      top: 5,
                      fontSize: 24,
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={(e) =>
                      handleDeleteImage(index, setImagesPreview, setFormData)
                    }
                  >
                    X
                  </span>
                  <img
                    key={index}
                    src={item}
                    alt="часы"
                    className={styles.sliderAccessories__image}
                    style={{ width: sliderItemWidth }}
                  />
                </div>
              ))}
            {imagesPreview?.map((item, index) => (
              <div style={{ position: "relative" }} key={index}>
                <span
                  style={{
                    position: "absolute",
                    right: 5,
                    top: 5,
                    fontSize: 24,
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={(e) =>
                    handleDeleteImage(index, setImagesPreview, setFormData)
                  }
                >
                  X
                </span>
                <img
                  key={index}
                  src={item}
                  alt="ювелирка"
                  className={styles.sliderAccessories__image}
                  style={{ width: sliderItemWidth }}
                />
              </div>
            ))}
          </div>
        </div>
        <div
          className={styles.sliderAccessories__btnLeft}
          onClick={prevSlider}
        ></div>
        <div
          className={styles.sliderAccessories__btnRight}
          onClick={nextSlider}
        ></div>
      </div>
      <div style={{ display: "flex", alignItems: "center", columnGap: 20 }}>
        <label
          htmlFor="slider-accessories-label"
          className={styles.sliderAccessories__label}
        >
          Добавить изображения
          <input
            type="file"
            multiple="multiple"
            name="accessories"
            id="slider-accessories-label"
            onChange={(e) => handleImagesChange(e)}
            className={styles.sliderAccessories__input}
            ref={filesInputRef}
          />
        </label>
        {formData.accessories.length > 0 && (
          <button
            className={styles.deleteIcon}
            title="Удалить все изображения"
            onClick={() => handleDeleteImages(setImagesPreview, setFormData)}
          >
            <img src={deleteIcon} alt="" />
          </button>
        )}
      </div>
    </div>
  );
}
