import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  data: [],
  recommendation: [],
  currentWatch: {},
  createdWatch: {},
  editWatch: {},
  error: null,
  loading: false,
  message: "",
  filterRequest: false,
};

export const getWatch = createAsyncThunk(
  "get/watch",
  async (filterKeys = {}, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        config.proxy + "/watch/all",
        filterKeys,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editAvailability = createAsyncThunk(
  "edit/watch/availability",
  async ({ id, availability }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${config.proxy}/watch/update-availability/${id}`,
        { availability },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.data;
      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRecommendation = createAsyncThunk(
  "get/watch/recommendation",
  async (filterKeys = {}, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        config.proxy + "/watch/recommendation",
        filterKeys,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getWatchById = createAsyncThunk(
  "get/watch/id",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(config.proxy + `/watch/${id}`);
      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addWatch = createAsyncThunk(
  "add/watch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const postData = new FormData();

      postData.append("brand", formData.brand);
      postData.append("nameOfTheWatch", formData.nameOfTheWatch);
      postData.append("mainWatch", formData.mainWatch);
      postData.append("availability", formData.availability);
      postData.append("information", JSON.stringify(formData.information));
      postData.append("kit", formData.kit);
      postData.append("price", formData.price);
      postData.append("gender", formData.gender);
      postData.append("currency", formData.currency);

      formData.watch.forEach((watchFile) => {
        postData.append(`watch`, watchFile);
      });

      const response = await axios.post(`${config.proxy}/watch`, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const editWatchById = createAsyncThunk(
  "edit/watch/id",
  async ({ formData, id }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      const postData = new FormData();

      if (formData.brand?._id) {
        postData.append("brand", formData.brand._id);
      } else if (formData.brand) {
        postData.append("brand", formData.brand);
      }

      if (formData.nameOfTheWatch) {
        postData.append("nameOfTheWatch", formData.nameOfTheWatch);
      }

      if (formData.product) {
        postData.append("product", formData.product);
      }

      if (formData.kit) {
        postData.append("kit", formData.kit);
      }

      postData.append("price", formData.price);
      postData.append("gender", formData.gender);
      postData.append("currency", formData.currency);

      postData.append("availability", formData.availability);

      if (formData.images && formData.images.length > 0) {
        formData.images.forEach((image) => {
          postData.append(`watchImagesPath`, image);
        });
      }

      if (formData.oldImages && formData.oldImages.length > 0) {
        formData.oldImages.forEach((image) => {
          postData.append(`oldImages`, image);
        });
      }

      if (formData.mainImage) {
        postData.append("mainImagePath", formData.mainImage);
      }

      if (formData.mainWatch) {
        postData.append("mainWatch", formData.mainWatch);
      }

      if (formData.information) {
        postData.append("information", JSON.stringify(formData.information));
      }

      if (formData.watch && formData.watch.length > 0) {
        formData.watch.forEach((watchFile) => {
          postData.append("watch", watchFile);
        });
      }

      const response = await axios.patch(
        `${config.proxy}/watch/${id}`,
        postData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteWatchById = createAsyncThunk(
  "delete/watch/id",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${config.proxy}/watch/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const watchSlice = createSlice({
  name: "watch",
  initialState,
  reducers: {
    clearCreatedWatch: (state) => {
      state.createdWatch = {};
    },
    clearEditWatch: (state) => {
      state.editWatch = {};
    },
    clearCurrentWatch: (state) => {
      state.currentWatch = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWatch.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
        state.filterRequest = true;
      })
      .addCase(getWatch.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getWatch.fulfilled, (state, actions) => {
        state.data = actions.payload;
        state.loading = false;
        state.error = null;
        state.filterRequest = false;
      })

      .addCase(getRecommendation.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRecommendation.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getRecommendation.fulfilled, (state, actions) => {
        state.recommendation = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(addWatch.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addWatch.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(addWatch.fulfilled, (state, actions) => {
        state.createdWatch = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(getWatchById.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWatchById.rejected, (state, actions) => {
        state.error = actions.payload;
        state.loading = false;
      })
      .addCase(getWatchById.fulfilled, (state, actions) => {
        state.currentWatch = actions.payload;
        state.loading = false;
        state.error = null;
      })

      .addCase(deleteWatchById.pending, (state, actions) => {
        state.error = null;
      })
      .addCase(deleteWatchById.rejected, (state, actions) => {
        state.error = actions.payload;
      })
      .addCase(deleteWatchById.fulfilled, (state, actions) => {
        state.message = actions.payload.message;
        state.data = state.data.filter(
          (card) => card._id !== actions.payload.id
        );
        state.error = null;
      })

      .addCase(editWatchById.pending, (state, actions) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(editWatchById.rejected, (state, actions) => {
        state.error = actions.payload;
        state.loading = false;
      })
      .addCase(editWatchById.fulfilled, (state, actions) => {
        state.editWatch = actions.payload;
        state.loading = false;
        state.error = null;
      });
  },
});

export const { clearCreatedWatch, clearEditWatch, clearCurrentWatch } =
  watchSlice.actions;

export default watchSlice.reducer;
