import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  data: [],
  error: null,
  loading: false,
};

export const getTunings = createAsyncThunk(
  "get/tunings",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${config.proxy}/tunings`);
      const data = response.data;

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getTuningById = createAsyncThunk(
  "get/tuningById",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${config.proxy}/tuning/${id}`);
      const data = response.data;
      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addTuning = createAsyncThunk(
  "add/tuning",
  async ({ formData }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${config.proxy}/tuning`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const editTuning = createAsyncThunk(
  "edit/tuning",
  async ({ id, formData }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${config.proxy}/tuning/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteTuning = createAsyncThunk(
  "delete/tuning",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${config.proxy}/tuning/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response.data;
      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const tuningSlice = createSlice({
  name: "tuning",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTunings.fulfilled, (state, actions) => {
        state.data = actions.payload;
        state.error = null;
        state.loading = false;
      })

      .addCase(getTuningById.fulfilled, (state, actions) => {
        state.currentTuning = actions.payload;
        state.error = null;
        state.loading = false;
      })

      .addCase(addTuning.fulfilled, (state, actions) => {
        state.data = [...state.data, actions.payload];
        state.error = null;
        state.loading = false;
      })

      .addCase(editTuning.fulfilled, (state, actions) => {
        state.data = state.data.map((item) => {
          if (item._id === actions.payload._id) {
            return actions.payload;
          }
          return item;
        });
        state.error = null;
        state.loading = false;
      })

      .addCase(deleteTuning.fulfilled, (state, actions) => {
        state.data = state.data.filter(
          (item) => item._id !== actions.payload._id
        );
        state.error = null;
        state.loading = false;
      })

      .addCase(getTunings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getTuningById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(addTuning.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(editTuning.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(deleteTuning.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getTunings.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(getTuningById.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(addTuning.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(editTuning.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(deleteTuning.rejected, (state, actions) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default tuningSlice.reducer;
