import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import styles from "./App.module.scss";
import "./vendor/fonts/fonts.css";

import { useSelector } from "react-redux";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import NavigateWidthToPage from "./components/NavigateWidthToPage/NavigateWidthToPage";
import PopupFilter from "./components/PopupFilter/PopupFilter";
import PopupMenuLeft from "./components/PopupMenuLeft/PopupMenuLeft";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Accessories from "./pages/Accessories/Accessories";
import Accessory from "./pages/Accessory/Accessories";
import CreateAccessories from "./pages/CreateAccessories/CreateAccessories";
import EditAccessories from "./pages/EditAccessories/EditAccessories";
import Tuning from "./pages/Modding/Modding";
import PriceList from "./pages/PriceList/PriceList";

const Main = lazy(() => import("./pages/Main/Main"));
const Watchs = lazy(() => import("./pages/Watchs/Watchs"));
const Watch = lazy(() => import("./pages/Watch/Watch"));
const CreateWatch = lazy(() => import("./pages/CreateWatch/CreateWatch"));
const Service = lazy(() => import("./pages/Service/Service"));
const Moddings = lazy(() => import("./pages/Moddings/Moddings"));
const Jewelrys = lazy(() => import("./pages/Jewelrys/Jewelrys"));
const Jewelry = lazy(() => import("./pages/Jewelry/Jewelry"));
const AdminSignIn = lazy(() => import("./pages/AdminSignIp/AdminSignIn"));
const EditWatch = lazy(() => import("./pages/EditWatch/EditWatch"));
const CreateJewelry = lazy(() => import("./pages/CreateJewelry/CreateJewelry"));
const ModdingOne = lazy(() => import("./pages/ModdingOne/ModdingOne"));
const MobileLocation = lazy(() =>
  import("./pages/MobileLocation/MobileLocation")
);
const EditJewelry = lazy(() => import("./pages/EditJewelry/EditJewelry"));
const Brands = lazy(() => import("./pages/Brands/Brands"));
const MainMobile = lazy(() => import("./pages/MainMobile/MainMobile"));
const Filter = lazy(() => import("./pages/Filter/Filter"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
const ModdingTwo = lazy(() => import("./pages/ModdingTwo/ModdingTwo"));
const News = lazy(() => import("./pages/News/News"));

const App = () => {
  const [menuLeftIsActive, setMenuLeftIsActive] = useState(false);
  const [popupFilterActive, setPopupFilterActive] = useState(false);
  const [popupAdminPanel, setPopupAdminPanel] = useState(false);

  const [countMobileMainPage, setCountMobileMainPage] = useState(0);
  const { signIn } = useSelector((state) => state.application);
  const [loggedIn, setLoggedIn] = useState(
    Boolean(localStorage.getItem("admin"))
  );

  const [firstVisit, setFirstVisit] = useState(true);

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  useEffect(() => {
    if (location.pathname !== "/" && window.innerWidth < 768)
      setFirstVisit(false);

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 50);
  }, [location.pathname]);

  useEffect(() => {
    checkToken();
  }, [loggedIn, signIn]);

  function checkToken() {
    const token = localStorage.getItem("admin");
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }

  const openMenuLeft = () => {
    setMenuLeftIsActive(true);
  };
  const openFilter = () => {
    setPopupFilterActive(true);
  };
  const openAdminPanel = () => {
    setPopupAdminPanel(!popupAdminPanel);
  };

  if (window.innerWidth < 1024) localStorage.clear();
  return (
    <div className={`${styles.page} page`}>
      <PopupMenuLeft
        isActive={menuLeftIsActive}
        setActive={setMenuLeftIsActive}
        setCountMobileMainPage={setCountMobileMainPage}
      />
      <PopupFilter
        isActive={popupFilterActive}
        setActive={setPopupFilterActive}
      />
      <AdminPanel isActive={popupAdminPanel} setActive={setPopupAdminPanel} />
      <Header
        openAdminPanel={openAdminPanel}
        openMenuLeft={openMenuLeft}
        firstVisit={firstVisit}
      />
      <div id="portal"></div>
      <div className={styles.wrapper}>
        <Suspense fallback={<></>}>
          <Routes>
            <Route
              path="/"
              element={
                window.innerWidth < 768 ? (
                  <MainMobile
                    firstVisit={firstVisit}
                    setFirstVisit={setFirstVisit}
                    setCountMobileMainPage={setCountMobileMainPage}
                    countMobileMainPage={countMobileMainPage}
                  />
                ) : (
                  <Main firstVisit={firstVisit} setFirstVisit={setFirstVisit} />
                )
              }
            />
            <Route
              path="/news"
              element={<News openMenuLeft={openMenuLeft} />}
            />
            <Route
              path="/price-list"
              element={<PriceList openMenuLeft={openMenuLeft} />}
            />
            <Route
              path="/filter"
              element={
                <Filter openMenuLeft={openMenuLeft} openFilter={openFilter} />
              }
            />

            <Route
              path="/watches"
              element={
                <NavigateWidthToPage
                  component={Watchs}
                  openMenuLeft={openMenuLeft}
                  openFilter={openFilter}
                />
              }
            />

            <Route
              path="/watches/:id"
              element={<Watch openMenuLeft={openMenuLeft} />}
            />

            <Route
              path="/jewelries"
              element={
                <NavigateWidthToPage
                  component={Jewelrys}
                  openMenuLeft={openMenuLeft}
                  openFilter={openFilter}
                />
              }
            />
            <Route
              path="/accessories"
              element={
                <NavigateWidthToPage
                  component={Accessories}
                  openMenuLeft={openMenuLeft}
                  openFilter={openFilter}
                />
              }
            />
            <Route
              path="/jewelries/:id"
              element={<Jewelry openMenuLeft={openMenuLeft} />}
            />
            <Route
              path="/accessories/:id"
              element={<Accessory openMenuLeft={openMenuLeft} />}
            />
            <Route
              path="/tuning/:id"
              element={<Tuning openMenuLeft={openMenuLeft} />}
            />
            <Route
              path="/tuning"
              element={<Moddings openMenuLeft={openMenuLeft} />}
            />
            {window.innerWidth > 768 && (
              <>
                <Route
                  path="/service"
                  element={<Service openMenuLeft={openMenuLeft} />}
                />
              </>
            )}
            {/* {window.innerWidth < 768 && (
              <Route path="/location" element={<MobileLocation />} />
            )} */}
            {/* <Route
              path="/tuning/one"
              element={<ModdingOne openMenuLeft={openMenuLeft} />}
            />
            <Route
              path="/tuning/two"
              element={<ModdingTwo openMenuLeft={openMenuLeft} />}
            /> */}
            <Route path="/admin/signIn" element={<AdminSignIn />} />

            {loggedIn && (
              <>
                <Route
                  path="/watches/admin/create"
                  element={
                    <ProtectedRoute
                      component={CreateWatch}
                      loggedIn={loggedIn}
                    />
                  }
                />
                <Route
                  path="/watches/admin/edit/:id"
                  element={
                    <ProtectedRoute
                      component={EditWatch}
                      openMenuLeft={openMenuLeft}
                      loggedIn={loggedIn}
                    />
                  }
                />
                <Route
                  path="/jewelries/admin/create"
                  element={
                    <ProtectedRoute
                      component={CreateJewelry}
                      openMenuLeft={openMenuLeft}
                      loggedIn={loggedIn}
                    />
                  }
                />
                <Route
                  path="/jewelries/admin/edit/:id"
                  element={
                    <ProtectedRoute
                      component={EditJewelry}
                      openMenuLeft={openMenuLeft}
                      loggedIn={loggedIn}
                    />
                  }
                />
                <Route
                  path="/accessories/admin/create"
                  element={
                    <ProtectedRoute
                      component={CreateAccessories}
                      openMenuLeft={openMenuLeft}
                      loggedIn={loggedIn}
                    />
                  }
                />
                <Route
                  path="/accessories/admin/edit/:id"
                  element={
                    <ProtectedRoute
                      component={EditAccessories}
                      openMenuLeft={openMenuLeft}
                      loggedIn={loggedIn}
                    />
                  }
                />
                <Route
                  path="/admin/brands"
                  element={
                    <ProtectedRoute component={Brands} loggedIn={loggedIn} />
                  }
                />
              </>
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default App;
