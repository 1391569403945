import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "../../app/features/currency.slice";
import styles from "./currencyselection.module.scss";

const CurrencySelection = ({ formData, setFormData, handleChange }) => {
  const currencies = useSelector((state) => state.currency);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currencies.data.length) dispatch(getCurrencies());
  }, [dispatch]);

  return (
    <div className={styles.currency}>
      <select
        id="currency"
        name="currency"
        onChange={handleChange}
        value={formData?.currency?.symbol || formData.currency || ""}
      >
        {currencies.data.map((currency, i) => (
          <option key={currency._id} value={currency.symbol}>
            {currency?.symbol}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CurrencySelection;
