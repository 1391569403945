import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  error: null,
  signIn: false,
  loading: false,
  token: localStorage.getItem("token"),
  userId: localStorage.getItem("admin"),
};

export const authSignIn = createAsyncThunk(
  "auth/signin",
  async ({ login, password }, thunkAPI) => {
    try {
      const response = await axios.post(`${config.proxy}/login`, {
        login,
        password,
      });
      const data = await response.data;

      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }

      data.token && localStorage.setItem("token", data.token);
      data.id && localStorage.setItem("admin", data.id);

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    exit: (state) => {
      state.signIn = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authSignIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authSignIn.rejected, (state, action) => {
        state.error = action.payload?.response?.data?.error;
        state.loading = false;
      })
      .addCase(authSignIn.fulfilled, (state, action) => {
        state.signIn = true;
        state.error = null;
        state.loading = false;
        state.token = action.payload?.token;
        state.userId = action.payload?.id;
      });
  },
});
export const { exit } = applicationSlice.actions;
export default applicationSlice.reducer;
