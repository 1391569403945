import React from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Helmet } from "react-helmet";
import styles from "./priceList.module.scss";

const rows = [
  [
    [
      "Anne Klein",
      "Aerowatch",
      "Armand Nicolet",
      "Alain Silberstein	",
      "A. Lange & Söhne",
    ],
    ["Appella", "Alpina", "Burberry", "Baume Mercier", "Armin Storm"],
    ["Atlantic", "Armand Nicolet", "Ball", "Bell & Ross", "Arnold & Son"],
    ["Aviator", "Auguste Reymond", "Bucherer", "Breitling", "Audemars Piguet"],
    ["Armani", "Balmain", "Bulova", "Carl F. Bucherer", "Blancpain"],
    ["Bering", "BRM", "Charriol", "Carrera y Carrera", "Blu"],
    ["Calvin Klein", "Charles-August Pillard", "Eterna", "Cartier", "Bovet"],
    ["Candino", "Doxa", "Epos", "Chanel", "Breguet"],
    ["Certina", "Edox", "Fortis", "Chaumet", "Bulgari"],
    ["Charmex", "Escada", "Jaguar", "Chronoswiss", "Chopard"],
    ["Claude Bernard", "Fendi", "Ikepod", "Concord", "Custos"],
    ["Cover", "Frederique Constant", "Longines", "Corum", "Daniel Roth"],
    [
      "Daniel Wellington",
      "Gc",
      "Maurice Lacroix",
      "Cuervo y Sobrinos",
      "de Grisogono",
    ],
    ["Diesel", "Gucci", "Mido", "Delaneau", "Delaneau"],
    ["DKNY", "Haas & Cie", "Movado", "Dior", "Dewitt"],
    [
      "Dolce & Gabbana",
      "Hamilton",
      "Oris",
      "Dubey & Schaldenbrand",
      "Franck Muller",
    ],
    ["Ferrari", "Jaguar", "Rado", "Ebel", "Gerald Genta"],
    [
      "Festina",
      "John Galliano",
      "Raymond Weil",
      "Eberhard",
      "Girard Perregaux",
    ],
    ["Fossil", "Lorenz", "Swiza", "Franc Vila", "Glashütte original"],
    ["GF Ferre", "Louis Erard", "Tag Heuer", "Graham", "Harry Winston"],
    ["Givenchy", "Michel Herbelin", "Titoni", "Hermes", "Hublot"],
    ["Grovana", "Milus", " ", "Hysek(Jorg)", "HYT"],
    ["Guess", "Nautica", " ", "Ice Link", "IWC"],
    ["Hugo Boss", "Nina Ricci", " ", "JeanMairet&Gillman", "Jacob & CO"],
    ["Ingersoll", "Philippe de Cheron", " ", "Korloff", "Jaeger LeCoultre"],
    ["Invicta", "Pierre Cardin", " ", "Louis Vuitton", "Jaquet Droz"],
    ["Jacques Lemans", "Revue Thommen", " ", "Montblanc", "Jean Richard"],
    ["Kolber", "Rieman", " ", "Officine Panerai", "Louis Moinet"],
    ["Luminox", "Roamer", " ", "Omega", "MB&F"],
    ["Marvin", "Rotary", " ", "Pequignet", "Parmigiani Fleurier"],
    ["Michael Kors", "RSW", " ", "Perrelet", "Patek Philippe"],
    ["Moschino", "Saint Honore", " ", "Porsche Design", "Piaget"],
    ["Philip Laurence", "TechnoMarine", " ", "Romain Jerome", "Quinting"],
    ["Roberto Cavalli", "Titoni", " ", "Sevenfriday", "Richard Mille"],
    ["Romanson", "Versace", " ", "Tudor", "Roger Dubuis"],
    ["Sector", "Victorinox", " ", "U-Boat", "Rolex"],
    ["Skagen", "", "", "Ulysse Nardin", "Urwerk"],
    ["Swatch", "", "", "Van Der Bauwede", "Vacheron constantin"],
    ["Swiss Military", "", "", "", "Van Cleef & Arpels"],
    ["Timex", "", "", "", "Zenith"],
    ["Tissot", "", "", "", ""],
    ["Wenger", "", "", "", ""],
  ],
  ["2000 руб.", "2000 руб.", "3000 руб.", "3000 руб.", "От 3000 руб."],
  [
    [
      "Механические с ручным заводом",
      "10000 руб.",
      "12000 руб.",
      "От 14000 руб.",
      "От 16000 руб.",
      "От 26000 руб.",
    ],
    [
      "Механические с автоподзаводом",
      "12000 руб.",
      "14000 руб.",
      "От 16000 руб.",
      "От 20000 руб.",
      "От 29000 руб.",
    ],
    [
      "Хронограф с автоподзаводом",
      "15000 руб.",
      "19000 руб.",
      "От 23000 руб.",
      "От 27000 руб.",
      "От 39000 руб.",
    ],
    [
      "Часы механические с усложнениями",
      "17000 руб.",
      "24000 руб.",
      "От 28000 руб.",
      "От 33000 руб.",
      "От 46000 руб.",
    ],
  ],
  [
    [
      "Простые кварцевые часы",
      "9000 руб.",
      "11000 руб.",
      "13000 руб.",
      "От 15000 руб.",
      "От 23000 руб.",
    ],
    [
      "Кварцевый Хронограф",
      "12000 руб.",
      "14000 руб.",
      "18000 руб.",
      "От 20000 руб.",
      "От 28000 руб.",
    ],
    [
      "Сложный кварцевый хронограф",
      "14000 руб.",
      "16000 руб.",
      "От 22000 руб.",
      "От 23000 руб.",
      "От 33000 руб.",
    ],
    ["Дополнительная функциия", "30%", "30%", "30%", "30%", "30%"],
    ["Механизм со следами коррозии", "75%", "75%", "75%", "75%", "75%"],
    ["Сложный корпус", "50%", "50%", "50%", "50%", "50%"],
    ["Корпус из драгметалла/камнями", "50%", "50%", "50%", "50%", "50%"],
    ["Часы с годом выпуска более 25 лет", "50%", "50%", "50%", "50%", "50%"],
  ],
  [
    [
      "Замена источника питания",
      "1000 руб.",
      "1300 руб.",
      "1800 руб.",
      "2500 руб.",
      "3000 руб.",
    ],
    [
      "Установка / браслета (клипсы)",
      "1000 руб.",
      "1000 руб.",
      "1500 руб.",
      "2000 руб.",
      "2500 руб.",
    ],
    [
      "Укорачивание браслета",
      "1000 руб.",
      "1000 руб.",
      "1500 руб.",
      "2000 руб.",
      "2500 руб.",
    ],
    [
      "Установка стрелки для кварцевых часов, от",
      "1500 руб.",
      "2000 руб.",
      "2500 руб.",
      "3000 руб.",
      "3500 руб.",
    ],
    [
      "Установка метки/индекса",
      "1000 руб.",
      "1500 руб.",
      "2000 руб.",
      "2500 руб.",
      "3000 руб.",
    ],
    [
      "За каждый последующий знак",
      "500 руб.",
      "500 руб.",
      "500 руб.",
      "1000 руб.",
      "1500 руб.",
    ],
    [
      "Проверка герметичности",
      "1000 руб.",
      "1000 руб.",
      "1500 руб.",
      "2000 руб.",
      "2500 руб.",
    ],
    [
      "Проверка на подлинность",
      "3000 руб.",
      "3000 руб.",
      "3000 руб.",
      "3000 руб.",
      "3000 руб.",
    ],
    [
      "Проверка точности хода",
      "500 руб.",
      "500 руб.",
      "1000 руб.",
      "1500 руб.",
      "2000 руб.",
    ],
    [
      "Регулировка точности хода",
      "1000 руб.",
      "1500 руб.",
      "от 2000 руб.",
      "от 2500 руб.",
      "от 4000 руб.",
    ],
    [
      "Мойка корпуса и браслета в УЗ ванне",
      "1500 руб.",
      "1500 руб.",
      "2000 руб.",
      "3000 руб.",
      "4000 руб.",
    ],
    [
      "Полировка корпуса часов на ремне, от",
      "10000 руб.",
      "12000 руб.",
      "12000 руб.",
      "14000 руб.",
      "16000 руб.",
    ],
    [
      "Полировка часов на браслете, от",
      "12000 руб.",
      "12000 руб.",
      "14000 руб.",
      "16000 руб.",
      "18000 руб.",
    ],
  ],
];

export function BasicTable() {
  return (
    <TableContainer
      sx={{
        background: "rgba(255, 255, 255, 0.1)",
        "& th": { color: "#fff" },
        "& td": { color: "#fff" },
      }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} size="medium" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right" sx={{ paddingRight: "12%" }} colSpan={3}>
              Ремонт механизма{" "}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              Модель часов
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows[0].map((row, index) => (
            <TableRow
              key={index + "row1"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              {rows[0][index].map((item, i) => (
                <TableCell
                  key={i + "cell1"}
                  align={`${i === 0 ? "right" : i === 1 ? "left" : "left"}`}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              Диагностика
            </TableCell>
            {rows[1].map((item, i) => (
              <TableCell
                key={i + "cell2"}
                align={`${i === 0 ? "right" : i === 1 ? "left" : "left"}`}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={6}>
              Механические часы (без учёта запчастей)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows[2].map((row, index) => (
            <TableRow
              key={index + "row3"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {rows[2][index].map((item, i) => (
                <TableCell
                  key={i + "cell3"}
                  component={`${i === 0 ? "th" : "td"}`}
                  align={`${i === 0 ? "left" : i === 1 ? "right" : "left"}`}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={6}>
              Кварцевые часы (без учёта запчастей)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows[3].map((row, index) => (
            <TableRow
              key={index + "row4"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {rows[3][index].map((item, i) => (
                <TableCell
                  key={i + "cell4"}
                  component={`${i === 0 ? "th" : "td"}`}
                  align={`${i === 0 ? "left" : i === 1 ? "right" : "left"}`}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow>
            <TableCell component="th" colSpan={4}>
              Сложный механизм
            </TableCell>
            <TableCell align="left" colSpan={2}>
              Цена договорная
            </TableCell>
          </TableRow>
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={6}>
              Мелкий Ремонт
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows[4].map((row, index) => (
            <TableRow
              key={index + "row5"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {rows[4][index].map((item, i) => (
                <TableCell
                  key={i + "cell5"}
                  component={`${i === 0 ? "th" : "td"}`}
                  align={`${i === 0 ? "left" : i === 1 ? "right" : "left"}`}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const PriceList = ({ openMenuLeft }) => {
  return (
    <main className={styles.pricePage}>
      <Helmet>
        <title>{`Lansoir - Прайс-Лист`}</title>
      </Helmet>
      <div className={styles.price}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.price__line}>
              <div
                className={styles.price__lineIcon}
                onClick={openMenuLeft}
              ></div>
              <p className={styles.price__lineText}>Прайс-Лист</p>
            </div>
            <div className={styles.price__row}>
              <BasicTable />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PriceList;
