import { useEffect, useRef, useState } from "react";
import { config } from "../../config";
import deleteIcon from "../../images/watchCard/delete-icons.svg";
import styles from "./EditAccessoriesSlider.module.scss";

export default function EditAccessoriesSlider({
  formData,
  handleImagesChange,
  imagesPreview,
  setFormData,
  setImagesPreview,
  filesInputRef,
}) {
  const [sliderCount, setSliderCount] = useState(0);
  const sliderContainerRef = useRef(null);
  const [sliderContainerWidth, setSliderContainerWidth] = useState(0);
  const [formDataGeneralImages, setFormDataGeneralImages] = useState([]);
  const sliderItemWidth = `${sliderContainerWidth / 3}px`;

  useEffect(() => {
    if (formData.images?.length > 0)
      setFormDataGeneralImages([...formData.images, ...formData.accessories]);
    if (sliderContainerRef.current) {
      setSliderContainerWidth(sliderContainerRef.current.offsetWidth);
    }
  }, [formData?.accessories, formData?.images]);

  function prevSlider() {
    setSliderCount((prevCount) =>
      prevCount > 0 ? prevCount - 1 : formDataGeneralImages.length - 1
    );
  }

  function nextSlider() {
    setSliderCount((prevCount) =>
      prevCount < formDataGeneralImages.length - 1 ? prevCount + 1 : 0
    );
  }

  const handleDeleteImage = (index, setImagesPreview, setFormData) => {
    const updatedFormData = formData?.accessories?.filter((item, i) => index !== i);
    const updatedImagesPreview = imagesPreview.filter((item, i) => index !== i);
    setFormData({ ...formData, accessories: updatedFormData });
    setImagesPreview(updatedImagesPreview);
  };

  const handleDeletePathImage = (index, setFormData) => {
    const updatedImages = formData?.images?.filter((item, i) => index !== i);
    const oldImages = formData?.images?.filter(
      (item) => !updatedImages.includes(item)
    );
    setFormData({ ...formData, images: updatedImages, oldImages });
  };

  const handleDeleteImages = (setImagesPreview, setFormData) => {
    setFormData({
      ...formData,
      accessories: [],
      oldImages: formData.images,
      images: [],
    });
    setImagesPreview([]);
    filesInputRef.current.value = null;
  };

  return (
    <div className={styles.sliderWatch}>
      <div className={styles.sliderWatch__container} ref={sliderContainerRef}>
        <div className={styles.sliderWatch__list}>
          <div
            className={styles.sliderWatch__containerSlider}
            style={{
              transform: `translateX(${-sliderCount * (100 / 3)}%)`,
            }}
          >
            {formData.images &&
              formData?.images?.map((item, index) => (
                <div style={{ position: "relative" }} key={index}>
                  <span
                    style={{
                      position: "absolute",
                      right: 5,
                      top: 5,
                      fontSize: 24,
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleDeletePathImage(index, setFormData)}
                  >
                    X
                  </span>
                  <img
                    key={index}
                    src={`${config.proxy}/${item}`}
                    alt="часы"
                    className={styles.sliderWatch__image}
                    style={{ width: sliderItemWidth }}
                  />
                </div>
              ))}
            {imagesPreview?.map((item, index) => (
              <div style={{ position: "relative" }} key={index}>
                <span
                  style={{
                    position: "absolute",
                    right: 5,
                    top: 5,
                    fontSize: 24,
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={(e) =>
                    handleDeleteImage(index, setImagesPreview, setFormData)
                  }
                >
                  X
                </span>
                <img
                  key={index}
                  src={item}
                  alt="часы"
                  className={styles.sliderWatch__image}
                  style={{ width: sliderItemWidth }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.sliderWatch__btnLeft} onClick={prevSlider}></div>
        <div
          className={styles.sliderWatch__btnRight}
          onClick={nextSlider}
        ></div>
      </div>
      <div style={{ display: "flex", alignItems: "center", columnGap: 20 }}>
        <label
          htmlFor="slider-accessories-label-edit"
          className={styles.sliderWatch__label}
        >
          Добавить изображения
          <input
            type="file"
            multiple="multiple"
            name="accessories"
            id="slider-accessories-label-edit"
            onChange={(e) => handleImagesChange(e)}
            className={styles.sliderWatch__input}
            ref={filesInputRef}
          />
        </label>
        {formDataGeneralImages.length > 0 && (
          <button
            type="button"
            className={styles.deleteIcon}
            title="Удалить все изображения"
            onClick={() => handleDeleteImages(setImagesPreview, setFormData)}
          >
            <img src={deleteIcon} alt="" />
          </button>
        )}
      </div>
    </div>
  );
}
