import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAccessories } from "../../app/features/accessories.slice";
import { getJewelry } from "../../app/features/jewelry.slice";
import { getWatch } from "../../app/features/watch.slice";
import styles from "./PopupMenuLeft.module.scss";

export default function PopupMenuLeft({
  isActive,
  setActive,
  setCountMobileMainPage,
}) {
  const [clickWatch, setClickWatch] = useState("");
  const [clickJewelry, setClickJewelry] = useState("");
  const [clickAccessories, setClickAccessories] = useState("");

  const brands = useSelector((state) => state.brand.data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const brandsWatch = brands.filter((brand) => brand.isWatch);
  const brandsJewelry = brands.filter((brand) => brand.isJewelry);
  const brandsAccessory = brands.filter((brand) => brand.isAccessories);

  const navigation = useNavigate();

  const handleClickWatch = (index) => {
    setClickWatch(index);
  };

  const handleClickJewelry = (index) => {
    setClickJewelry(index);
  };
  const handleClickAccessories = (index) => {
    setClickAccessories(index);
  };

  const handleClickWatchFilter = (filterKeys) => {
    dispatch(getWatch(filterKeys));
    navigate("/watches");
    setActive(false);
  };

  const handleClickJewelryFilter = (filterKeys) => {
    dispatch(getJewelry(filterKeys));
    navigate("/jewelries");
    setActive(false);
  };
  const handleClickAccessoriesFilter = (filterKeys) => {
    dispatch(getAccessories(filterKeys));
    navigate("/accessories");
    setActive(false);
  };

  const screenWidth = window.innerWidth;
  const handleClickExitPopup = (e) => {
    if (e.target === e.currentTarget) {
      setActive(false);
    }
  };

  const goNavigateWatchs = () => {
    if (screenWidth > 767) {
      navigation("/watches");
    } else {
      navigation("/");
      setCountMobileMainPage(1);
    }

    setActive(false);
  };
  const goNavigateJewerlys = () => {
    if (screenWidth > 767) {
      navigation("/jewelries");
    } else {
      navigation("/");
      setCountMobileMainPage(2);
    }
    setActive(false);
  };
  const goNavigateAccessories = () => {
    if (screenWidth > 767) {
      navigation("/accessories");
    }
    setActive(false);
  };
  const goNavigateModdings = () => {
    if (screenWidth > 767) {
      navigation("/tuning");
    } else {
      navigation("/");
      setCountMobileMainPage(3);
    }
    setActive(false);
  };
  const goNavigateService = () => {
    if (screenWidth > 767) {
      navigation("/service");
    } else {
      navigation("/");
      setCountMobileMainPage(4);
    }
    setActive(false);
  };

  return (
    <motion.div
      animate={{ x: isActive ? 0 : "-200vw", transition: { duration: 0.1 } }}
      onClick={handleClickExitPopup}
      className={`${styles.popupMenuLeft} ${
        isActive ? `${styles.popupMenuLeft_active}` : ""
      }`}
    >
      <div className={styles.popupMenuLeft__container}>
        <div className={styles.popupMenuLeft__header}></div>
        <nav className={styles.popupMenuLeft__nav}>
          <div className={styles.popupMenuLeft__mobileIcon}></div>
          <h4
            className={styles.popupMenuLeft__title}
            onClick={() => goNavigateWatchs()}
          >
            ЧАСЫ
          </h4>
          <ul className={styles.popupMenuLeft__navList}>
            <li className={styles.popupMenuLeft__navItem}>
              <div
                className={styles.popupMenuLeft__text}
                onClick={() => {
                  goNavigateWatchs();
                  dispatch(getWatch());
                }}
              >
                ВСЕ МОДЕЛИ
              </div>
            </li>
            {brandsWatch?.map((brand, index) => (
              <li className={styles.popupMenuLeft__navItem} key={index}>
                <div
                  className={`${styles.popupMenuLeft__text} ${
                    index === clickWatch
                      ? styles.popupMenuLeft__text_active
                      : ""
                  }`}
                  onClick={() => {
                    handleClickWatch(index);
                  }}
                >
                  <span
                    className={`${styles.symbol} ${
                      index === clickWatch ? styles.symbol_active : ""
                    }`}
                  >
                    {brand.kit.length ? "▼" : ""}
                  </span>
                  <span
                    onClick={() =>
                      handleClickWatchFilter({ brand: [brand._id] })
                    }
                  >
                    {brand?.nameOfTheBrand}
                  </span>
                  {brand?.kit?.map((kit, index) => (
                    <div
                      className={styles.popupMenuLeft__textList}
                      key={index}
                      onClick={() =>
                        handleClickWatchFilter({
                          brand: [brand._id],
                          kit: kit,
                        })
                      }
                    >
                      {kit}
                    </div>
                  ))}
                </div>
              </li>
            ))}
          </ul>
          <h4
            className={styles.popupMenuLeft__title}
            onClick={() => {
              goNavigateJewerlys();
              dispatch(getJewelry());
            }}
          >
            ЮВЕЛИРНЫЕ УКРАШЕНИЯ
          </h4>
          <ul className={styles.popupMenuLeft__navList}>
            <li className={styles.popupMenuLeft__navItem}>
              <div className={styles.popupMenuLeft__text}>ВСЕ МОДЕЛИ</div>
            </li>
            {brandsJewelry?.map((brand, index) => (
              <li className={styles.popupMenuLeft__navItem} key={index}>
                <div
                  className={`${styles.popupMenuLeft__text} ${
                    index === clickJewelry
                      ? styles.popupMenuLeft__text_active
                      : ""
                  }`}
                  onClick={() => {
                    handleClickJewelry(index);
                  }}
                >
                  <span
                    className={`${styles.symbol} ${
                      index === clickJewelry ? styles.symbol_active : ""
                    }`}
                  >
                    {brand.kit.length ? "▼" : ""}
                  </span>
                  <span
                    onClick={() =>
                      handleClickJewelryFilter({ brand: [brand._id] })
                    }
                  >
                    {brand?.nameOfTheBrand}
                  </span>
                  {brand?.kit?.map((kit, index) => (
                    <div
                      className={styles.popupMenuLeft__textList}
                      key={index}
                      onClick={() =>
                        handleClickJewelryFilter({
                          brand: [brand._id],
                          kit: kit,
                        })
                      }
                    >
                      {kit}
                    </div>
                  ))}
                </div>
              </li>
            ))}
          </ul>
          {window.innerWidth > 767 && (
            <>
              <h4
                className={styles.popupMenuLeft__title}
                onClick={() => goNavigateAccessories()}
              >
                АКСЕССУАРЫ
              </h4>
              <ul className={styles.popupMenuLeft__navList}>
                <li className={styles.popupMenuLeft__navItem}>
                  <div
                    className={styles.popupMenuLeft__text}
                    onClick={() => {
                      goNavigateAccessories();
                      dispatch(getAccessories());
                    }}
                  >
                    ВСЕ МОДЕЛИ
                  </div>
                </li>
                {brandsAccessory?.map((brand, index) => (
                  <li className={styles.popupMenuLeft__navItem} key={index}>
                    <div
                      className={`${styles.popupMenuLeft__text} ${
                        index === clickAccessories
                          ? styles.popupMenuLeft__text_active
                          : ""
                      }`}
                      onClick={() => {
                        handleClickAccessories(index);
                      }}
                    >
                      <span
                        className={`${styles.symbol} ${
                          index === clickAccessories ? styles.symbol_active : ""
                        }`}
                      >
                        {brand.kit.length ? "▼" : ""}
                      </span>
                      <span
                        onClick={() =>
                          handleClickAccessoriesFilter({ brand: [brand._id] })
                        }
                      >
                        {brand?.nameOfTheBrand}
                      </span>
                      {brand?.kit?.map((kit, index) => (
                        <div
                          className={styles.popupMenuLeft__textList}
                          key={index}
                          onClick={() =>
                            handleClickAccessoriesFilter({
                              brand: [brand._id],
                              kit: kit,
                            })
                          }
                        >
                          {kit}
                        </div>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
          <h4
            className={styles.popupMenuLeft__title}
            onClick={() => goNavigateModdings()}
          >
            ТЮНИНГ
          </h4>
          <h4
            className={styles.popupMenuLeft__title}
            onClick={() => goNavigateService()}
          >
            СЕРВИС
          </h4>
        </nav>
      </div>
    </motion.div>
  );
}
