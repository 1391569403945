import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAccessories } from "../../app/features/accessories.slice";
import { getBrands } from "../../app/features/brand.slice";
import CardAccessories from "../../components/CardAccessories/CardAccessories";
import { CardNav } from "../../components/CardNav/CardNav";
import WatchLoader from "../../components/WatchLoader/WatchLoader";
import styles from "./Accessories.module.scss";

import FilterInfo from "../../components/FilterInfo/FilterInfo";

export default function Accessories({ openMenuLeft, openFilter }) {
  const dispatch = useDispatch();
  const { data, loading, error, filterRequest } = useSelector(
    (state) => state.accessories
  );

  const brand = useSelector((state) => state.brand);

  const navigate = useNavigate();
  const [isActiveLengthSort, setActiveLengthSort] = useState(false);

  const admin = localStorage.getItem("admin");

  const [displayCards, setDisplayCards] = useState([]);
  const [isActiveBtnAddDisplayCards, setIsActiveBtnAddDisplayCards] =
    useState(true);
  const [countWidthMaxView, setCountWidthMaxView] = useState(12);

  const navList = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  useEffect(() => {
    if (!filterRequest && admin) {
      dispatch(getAccessories());
    } else if (!filterRequest && !data.length) {
      dispatch(getAccessories());
    }
    !brand.data.length && dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      startDisplayCards(data);
    }
  }, [data]);

  function startDisplayCards(data) {
    setDisplayCards(data.slice(0, 12));
    // скрываем кнопку Добавить еще, если больше нет карточек для отображения
    if (12 < data.length) {
      setIsActiveBtnAddDisplayCards(true);
    } else {
      setIsActiveBtnAddDisplayCards(false);
    }
  }

  function addDisplayCards() {
    setCountWidthMaxView((prevCountWidthMaxView) => prevCountWidthMaxView + 12);
  }

  useEffect(() => {
    setDisplayCards(data.slice(0, countWidthMaxView));
    // скрываем кнопку Добавить еще, если больше нет карточек для отображения
    if (countWidthMaxView <= data.length) {
      setIsActiveBtnAddDisplayCards(true);
    } else {
      setIsActiveBtnAddDisplayCards(false);
    }
  }, [countWidthMaxView]);

  const handleClickCreateAccessoriesPage = () => {
    setTimeout(() => {
      navigate("/accessories/admin/create");
    }, 300);
  };

  function handleClickIsActiveSort() {
    setActiveLengthSort(!isActiveLengthSort);
  }

  return (
    <main className={styles.accessoriesPage}>
      <Helmet>
        <title>Lansoir - Аксессуары</title>
      </Helmet>
      <nav className={styles.accessoriesPage__subNav}>
        <ul className={styles.accessoriesPage__navList}>
          <li className={styles.accessoriesPage__navItem}>
            <p
              className={styles.accessoriesPage__navText}
              onClick={() => navigate("/")}
            >
              новинки
            </p>
          </li>
          <li className={styles.accessoriesPage__navItem}>
            <div className={styles.accessoriesPage__lineHeight}></div>
            <p
              className={`${styles.accessoriesPage__navText} ${styles.accessoriesPage__navText_active}`}
            >
              ВСЕ МОДЕЛИ
            </p>
          </li>
        </ul>
      </nav>

      <div className={styles.accessories}>
        <FilterInfo
          openFilter={openFilter}
          admin={admin}
          text="Добавить Aксессуар"
          handleClickCreateJewelryPage={handleClickCreateAccessoriesPage}
          handleClickIsActiveSort={handleClickIsActiveSort}
        />
        <ul className={styles.accessories__linkListMobile}>
          <li className={styles.accessories__linkItem}>
            <p className={styles.accessories__linkWatchsText}>ЧАСЫ</p>
            <div className={styles.accessories__linkWatchsImg} />
          </li>
          <li className={styles.accessories__linkItem}>
            <p className={styles.accessories__linkRingsText}>КОЛЬЦА</p>
            <div className={styles.accessories__linkRingsImg} />
          </li>
          <li className={styles.accessories__linkItem}>
            <p className={styles.accessories__linkBraceletsText}>БРАСЛЕТЫ</p>
            <div className={styles.accessories__linkBraceletsImg} />
          </li>
        </ul>

        <div className={styles.accessories__container}>
          <nav className={styles.nav}>
            <ul className={styles.nav__navList}>
              {brand?.data
                ?.filter((item) => item.isAccessories)
                .map((data, index) => {
                  return (
                    <CardNav
                      brand={data}
                      key={data._id + index + "accessories"}
                    />
                  );
                })}
            </ul>
          </nav>
          <div className={styles.accessories__cardsInfo}>
            <div className={styles.accessories__line}>
              <div
                className={styles.accessories__lineIcon}
                onClick={openMenuLeft}
              ></div>
              <p
                className={styles.accessories__lineText}
                onClick={(e) => {
                  dispatch(getAccessories());
                }}
              >
                Аксессуары
              </p>
            </div>
            <div className={styles.accessories__cards}>
              {loading ? (
                <WatchLoader />
              ) : (
                <>
                  <motion.ul
                    variants={navList}
                    initial="hidden"
                    animate="visible"
                    className={
                      isActiveLengthSort
                        ? styles.accessories__listCardsLengthActive
                        : styles.accessories__listCards
                    }
                  >
                    {displayCards.map((item, index) => {
                      return (
                        <CardAccessories
                          card={item}
                          key={index + item._id + "accessories"}
                          isActiveLengthSort={isActiveLengthSort}
                        />
                      );
                    })}
                  </motion.ul>
                  <div className={styles.accessories__seeBlock}>
                    {data.length <= 0 && filterRequest ? (
                      <p className={styles.accessories__seeBtnText}>
                        НИЧЕГО НЕ НАЙДЕНО
                      </p>
                    ) : (
                      isActiveBtnAddDisplayCards && (
                        <>
                          <p
                            className={styles.accessories__seeBtnText}
                            onClick={() => addDisplayCards()}
                          >
                            СМОТРЕТЬ ЕЩЕ
                          </p>
                          <div className={styles.accessories__seeIconBtn}></div>
                        </>
                      )
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
