import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../../app/features/brand.slice";
import styles from "./brandselection.module.scss";

const BrandSelection = ({ formData, setFormData, handleChange, type }) => {
  const brands = useSelector((state) => state.brand);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!brands.data.length) dispatch(getBrands());
  }, [dispatch]);

  return (
    <div className={styles.brand}>
      <select
        id="brand"
        name="brand"
        placeholder="Выберите бренд"
        onChange={handleChange}
        value={formData?.brand?._id || formData?.brand || ""}
      >
        <option value="" disabled hidden>
          Выберите Бренд
        </option>
        {brands.data
          ?.filter((brand) => (brand[type]))
          .map((brand, i) => (
            <option key={brand._id} value={brand._id}>
              {brand?.nameOfTheBrand}
            </option>
          ))}
      </select>
    </div>
  );
};

export default BrandSelection;
