import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteAccessoriesById,
  editAvailability
} from "../../app/features/accessories.slice";
import { config } from "../../config";
import editIcon from "../../images/edit-btn.svg";
import deleteIcon from "../../images/watchCard/delete-icons.svg";
import styles from "./CardAccessories.module.scss";

export default function CardAccessories({ card, isActiveLengthSort }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [availability, setAvailability] = useState(card.availability);

  const item = {
    hidden: { y: 500, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const handleSetAvailability = (e) => {
    dispatch(
      editAvailability({
        availability: !availability,
        id: card._id,
      })
    );
    setAvailability(!availability);
  };

  const admin = localStorage.getItem("admin");

  const handleNavigateaccessoriesPage = () => {
    navigate(`/accessories/${card._id}`);
  };

  const handleNavigateEditaccessoriesPage = () => {
    navigate(`/accessories/admin/edit/${card._id}`);
  };

  const handleClickDeleteCard = () => {
    dispatch(deleteAccessoriesById(card._id));
  };

  return (
    <motion.li
      variants={item}
      className={
        isActiveLengthSort
          ? styles.cardAccessories__itemCardActiveSize
          : styles.cardAccessories__itemCard
      }
      onClick={handleNavigateaccessoriesPage}
    >
      {admin && (
        <span
          style={{
            display: "block",
            position: "absolute",
            width: 24,
            right: 10,
            top: 10,
            cursor: "pointer",
          }}
          onClick={(e) => {
            handleClickDeleteCard();
            e.stopPropagation();
          }}
        >
          <img src={deleteIcon} alt="" width={"100%"} />
        </span>
      )}
      {admin && (
        <span
          style={{
            display: "block",
            position: "absolute",
            width: 24,
            left: 10,
            top: 10,
            cursor: "pointer",
          }}
          onClick={(e) => {
            handleNavigateEditaccessoriesPage();
            e.stopPropagation();
          }}
        >
          <img src={editIcon} alt="" width={"100%"} />
        </span>
      )}
      <div
        className={
          isActiveLengthSort
            ? styles.cardAccessories__cardTextBlockActiveSize
            : styles.cardAccessories__cardTextBlock
        }
      >
        <h3
          className={
            isActiveLengthSort
              ? styles.cardAccessories__titleCardActiveSize
              : styles.cardAccessories__titleCard
          }
        >
          {card.brand?.nameOfTheBrand}
        </h3>
        {!isActiveLengthSort && <span className={styles.kit}>{card.kit}</span>}
        <p
          className={
            isActiveLengthSort
              ? styles.cardAccessories__subtitleCardActiveSize
              : styles.cardAccessories__subtitleCard
          }
        >
          {card.information?.description?.short}
        </p>
        <p
          className={
            isActiveLengthSort
              ? styles.cardAccessories__priceCardActiveSize
              : styles.cardAccessories__priceCard
          }
        >
          {card.price > 0 &&
            card?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
              " " +
              card.currency}
        </p>
        {isActiveLengthSort && (
          <p className={styles.cardAccessories__moreDetailsText}>
            ПОДРОБНЕЕ...
          </p>
        )}
      </div>
      <div className={styles.cardAccessories__imgContainer}>
        {card.mainImage && (
          <img
            className={
              isActiveLengthSort
                ? styles.cardAccessories__imgCardActiveSize
                : styles.cardAccessories__imgCard
            }
            alt="часы"
            src={`${config.proxy}/${card.mainImage}`}
            onLoad={() => setVisible(true)}
            style={{ display: visible ? "block" : "none" }}
          />
        )}
      </div>
      <div
        style={{
          maxWidth: 170,
          height: 333,
          display: visible ? "none" : "block",
        }}
      ></div>
      {!isActiveLengthSort && admin && (
        <div
          className={styles["not-available"]}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="checkbox"
            checked={availability}
            onChange={handleSetAvailability}
          />
          {availability ? "есть в наличии" : "нет в наличии"}
        </div>
      )}
    </motion.li>
  );
}
