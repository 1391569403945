import React from "react";
import { useSelector } from "react-redux";
import styles from "./kitselection.module.scss";

const KitSelection = ({ formData, setFormData, handleChange }) => {
  const brands = useSelector((state) => state.brand?.data);

  const brand = brands.find(
    (brand) => brand._id === (formData?.brand?._id || formData?.brand)
  );

  const kits = brand?.kit;

  return (
    <div className={styles.kit}>
      <select
        disabled={!formData.brand}
        id="kit"
        name="kit"
        placeholder="Выберите бренд"
        value={formData.kit || ""}
        onChange={handleChange}
      >
        <option value="" hidden style={{ fontSize: 24 }}>
          Выберите Коллекцию(опц.)
        </option>
        {kits?.map((kit) => (
          <option key={kit} value={kit}>
            {kit}
          </option>
        ))}
      </select>
    </div>
  );
};

export default KitSelection;
