import React, { useEffect, useState } from "react";
import styles from "./customcheckbox.module.scss";

const CustomCheckbox = ({ formData, handleChange }) => {
  const [availability, setAvailability] = useState(false);

  useEffect(() => {
    if (formData?.availability === true)
      setAvailability(formData?.availability);
    else setAvailability(false);
  }, [formData?.availability]);

  return (
    <div className={styles.container}>
      <label className={styles.switch} htmlFor="checkbox-switch">
        <input
          type="checkbox"
          id="checkbox-switch"
          name="availability"
          checked={availability}
          value={!availability}
          onChange={(e) => {
            setAvailability(!availability);
            handleChange(e);
          }}
        />
        <div className={`${styles.slider} ${styles.round}`}></div>
      </label>
      {availability ? "Есть в наличии" : "Нет в наличии"}
    </div>
  );
};

export default CustomCheckbox;
