import { configureStore } from "@reduxjs/toolkit";
import accessoriesSlice from "./features/accessories.slice";
import applicationSlice from "./features/application.slice";
import brandSlice from "./features/brand.slice";
import currencySlice from "./features/currency.slice";
import filterSlice from "./features/filter.slice";
import jewelrySlice from "./features/jewelry.slice";
import newsSlice from "./features/news.slice";
import productSlice from "./features/product.slice";
import tuningSlice from "./features/tuning.slice";
import watchSlice from "./features/watch.slice";

export const store = configureStore({
  reducer: {
    watch: watchSlice,
    brand: brandSlice,
    jewelry: jewelrySlice,
    accessories: accessoriesSlice,
    product: productSlice,
    filter: filterSlice,
    application: applicationSlice,
    news: newsSlice,
    currency: currencySlice,
    tuning: tuningSlice,
  },
});
