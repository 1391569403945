import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAccessories } from "../../app/features/accessories.slice";
import { getFilterData } from "../../app/features/filter.slice";
import { getJewelry } from "../../app/features/jewelry.slice";
import { getWatch } from "../../app/features/watch.slice";
import { config } from "../../config";
import styles from "./CardNav.module.scss";

export const CardNav = ({ brand }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClickIdBrand = (id) => {
    if (location.pathname === "/watches") {
      dispatch(getWatch({ brand: [brand._id] }));
    }
    if (location.pathname === "/jewelries") {
      dispatch(getJewelry({ brand: [brand._id] }));
    }
    if (location.pathname === "/accessories") {
      dispatch(getAccessories({ brand: [brand._id] }));
    }
    if (location.pathname === "/filter") {
      dispatch(getFilterData({ brand: [brand._id] }));
    }
  };

  return (
    <li className={styles.card__navItem} value={brand._id}>
      <div
        className={styles.card__navIcon}
        onClick={() => handleClickIdBrand(brand._id)}
      >
        <img
          src={`${config.proxy}/${brand.logo}`}
          alt={brand?.nameOfTheBrand}
        />
      </div>
      <div className={styles.card__navLine}></div>
    </li>
  );
};
